import './index.css'
import React from "react"
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"

import {Home} from './routes/Home'
import {Landing} from './routes/Landing'


const App = () => {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path='/' element={<Landing />} />
        </Routes>
      </Router>
    </div>
  )
}

export { App }
