import React, {useState, useEffect} from 'react'
import {getStyleConstants} from '../../styles/Colors'
import * as PU from '../../utils/parser'

import {FieldDropdown} from './FieldDropdown'

const TERNARY = 'ternary'
const MAP = 'map'
const extra_types = [TERNARY, MAP]

const VALUE_TYPES = {
    a: {
        name: 'Array<Object>',
        wrappers: ['[ {', '} ]'],
        options: null,
    },
    b: {
        name: 'Boolean',
        wrappers: ['', ''],
        options: [
            {name: 'true', value: true},
            {name: 'false', value: false}
        ],
        optionType: 'text'
    },
    e: {
        name: 'Expression',
        wrappers: ['', ''],
        options: null
    },
    n: {
        name: 'Number',
        wrappers: ['', ''],
        options: null
    },
    o: {
        name: 'Object',
        wrappers: ['{', '}'],
        options: null
    },
    s: {
        name: 'String',
        wrappers: ['"', '"'],
        options: null
    },
}

const PROP_FIELDS = {
    text: {
        options: [
            {name: 'Default', value: 'Insert Text Here'},
            {name: 'Male Name', value: 'John Smith'},
            {name: 'Female Name', value: 'Kelly Richards'},
            {name: 'Greeting', value: 'Hello how are you today?'}
        ],
        optionType: 'text'
    },
    source: {
        options: [
            {name: 'Profile 1', value: require('../../assets/pfp0.png')},
            {name: 'Profile 2', value: require('../../assets/pfp1.jpg')},
            {name: 'Profile 3', value: require('../../assets/pfp2.png')},
            {name: 'Profile 4', value: require('../../assets/pfp3.png')},
            {name: 'Chess Piece', value: require('../../assets/bk.png')}
        ],
        optionType: 'img'
    },

}

const getStyles = (isDarkMode) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc,
        cs,
        bgc, bc,
        cp
    } = getStyleConstants(isDarkMode) 

    const mb = 10
    const ml = 20

    return ({
        rootContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            overflow: 'scroll',
            padding: 15
        },
        fieldContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start'
        },
        fieldChildrenContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            paddingLeft: ml
        },
        fieldTitleContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: mb
        },
        fieldTitleText: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm
        },
        fieldValueInput: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm,
            padding: 5,
            border: `1px solid ${bc}`,
            marginBottom: mb
        },
        fieldValueText: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm
        },
    })
}

const PropsInput = props => {

    const {
        component,
        components,
        isDarkMode,
    } = props

    const [componentProps, setComponentProps] = useState({})

    const [visibleFieldID, setVisibleFieldID] = useState(null)

    // Function Helpers

    useEffect(() => {
        const updatedProps = PU.prop_types_to_object(component.prop_types)
        setComponentProps(updatedProps)
    }, [component])

    // Variable Declarations

    const styles = getStyles(isDarkMode)

    // Function Declarations

    
    // Render Functions

    const renderPropField = (propField) => {
        const valueType = propField.type.name
        const fieldName = propField.field.name
        const valueTypeFull = VALUE_TYPES[valueType]
        const propFieldFull = PROP_FIELDS[fieldName]

        const fieldOptions = propFieldFull ?
            propFieldFull.options
            : valueTypeFull.options ?
                valueTypeFull.options
                : []
        const fieldChildren = valueType === 'o' ?
            propField.fields
            : valueType === 'a' ?
                propField.element_type.fields
                : []
        const fieldHasInput = !(['a', 'o'].includes(valueType) || fieldOptions.length)

        const fieldWrappers = fieldHasInput || ['a', 'o'].includes(valueType) ? valueTypeFull.wrappers : ['', '']

        const optionsVisible = visibleFieldID === propField.id

        return (
            <div style={styles.fieldContainer}>
                <div style={styles.fieldTitleContainer}>
                    <p style={styles.fieldTitleText}>
                        {`${propField.name}  :  ${valueTypeFull.name}  =  ${fieldWrappers[0]}`}
                    </p>
                </div>
                <div style={styles.fieldChildrenContainer}>
                    {fieldOptions.length ?
                        <FieldDropdown
                            optionsVisible={optionsVisible}
                            options={fieldOptions}
                            onClickInput={() => setVisibleFieldID(optionsVisible ? null : propField.id)}
                            onClickOption={() => setVisibleFieldID(null)}
                            isDarkMode={isDarkMode}
                        /> : null
                    }
                    {fieldHasInput ?
                        <input
                            style={styles.fieldValueInput}
                            value={''}
                            onChange={e => {} }
                            spellCheck={false}
                            autoComplete='off'
                            autoCorrect='off'
                            autoCapitalize='off'
                        /> : null
                    }
                    {fieldChildren.map( f => renderPropField(f) )}
                </div>
                <div style={styles.fieldTitleContainer}>
                    <p style={styles.fieldTitleText}>
                        {fieldWrappers[1]}
                    </p>
                </div>
            </div>
        )
    }

    if (!component) return null

    return (
        <div style={styles.rootContainer}>
            {component.prop_types.map( f => renderPropField(f) )}
        </div>
    )
}

export {PropsInput}