import React, {useState, useEffect, useRef} from 'react'
import moment from 'moment'

import * as N from '../../navigation'
import {useNavigate} from 'react-router-dom'

import {getStyleConstants} from '../../styles/Colors'

// Utils

const p = (description, obj) => console.log(`${description} : ${JSON.stringify(obj, null, 4)}`)

// Constants


// Styles

const getStyles = (isDarkMode) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc,
        cs,
        bgc, bc,
        cp
    } = getStyleConstants(false)

    const hero_bgc = 'rgba(197,219,243,255)'
    const tint = 'rgba(12,124,138,255)'
    const sep_bc = 'black'
    const sep_border = '1px solid black'

    let sectionPadding = '50px 50px'
    if (window.innerWidth < 700) {
        sectionPadding = 20
    }

    return ({
        // containers
        rootContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            backgroundColor: bgc,
            position: 'relataive',
            width: '100vw'
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 75,
            position: 'sticky',
            top: 0,
            padding: '0px 20px',
            backgroundColor: 'white',
            borderBottom: sep_border
        },
        hero: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            backgroundColor: hero_bgc,
            borderBottom: sep_border,
            boxSizing: 'border-box',
            flexWrap: 'wrap',
        },
        features: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            padding: sectionPadding,
            borderBottom: sep_border
        },
        copyright: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: sectionPadding
        },
        heroLeftContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
             minWidth: 200,
            boxSizing: 'border-box',
            padding: sectionPadding,
        },
        heroRightContainer: {
            flex: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            overflow: 'hidden',
            boxSizing: 'border-box',
             minWidth: 'min(600px, 90vw)',
            padding: sectionPadding,
        },
        heroBodyImage: {
            border: `10px solid ${bc}`,
            borderRadius: 10, height: 'auto', maxWidth: '100%',
            maxHeight: 600,
            boxSizing: 'border-box'
        },
        // text
        headerTitleText: {
            color: cp,
            fontSize: 22,
            fontWeight: 600
        },
        copyrightText: {
            color: 'black',
            fontSize: 16,
            fontWeight: fwm
        },
        // common
        sectionTitleText: {
            color: 'black',
            fontSize: 28,
            fontWeight: 600
        },
        sectionBodyText: {
            color: cp,
            fontSize: 22,
            fontWeight: fwl
        },
        buttonContainer: {
            border: `2px solid black`,
            // borderRadius: 5,
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundColor: 'white',
            cursor: 'pointer'
        },
        buttonText: {
            color: 'black',
            fontSize: 18,
            fontWeight: 600,
            letterSpacing: 1
        }
    })
}

// Component

/*
SocialMediaPost

v root s s:c-s-fs bgc:white p:15 bc:gray bbw:1
    v header s s:r-c-fs
        i profile s hw:50 br:25 mr:10
        t name s c:black fs:m fw:l
    t body s c:black fs:m fw:m mv:10
    i bodyImage s br:10 bw:1 bc:gray ph:75
*/

const Landing = props => {

    // Hooks

    const navigate = useNavigate()

    // State Declarations

    const [isDarkMode, setIsDarkMode] = useState(true)

    // Helper Functions


    // Effects

    // Variable Declarations

    const styles = getStyles(isDarkMode)

    const content = {
        // hero
        headerTitleText: 'Reduced React',
        tagText: 'Build and simulate React Native components in seconds.',
        bodyText: "Tired of writing repetitive component code? <b>Reduced React</b> writes most of that for you. Just define the component tree, add some styles, and we’ll do the rest.",
        bodyImageSource: require('../../assets/home_ex_light.png'),
        ctaButtonText: 'Try playground',

        // features
        featureTitle: 'How it works',
        instructionSteps: [
            {}
        ],
        featureBody: '',
        copyrightText: '© 2022 Blackbox Solution Co. All rights reserved.'
    }

    // Function Declarations

    const onClickTryPlayground = () => {
        navigate(N.ROUTES.HOME)
    }

    return (
        <div style={styles.rootContainer}>
            <div style={styles.header}>
                <p style={styles.headerTitleText}>
                    {content.headerTitleText}
                </p>
            </div>
            <div style={styles.hero}>
                <div style={styles.heroLeftContainer}>
                    <p style={{...styles.sectionTitleText, marginBottom: 20}}>
                        {content.tagText}
                    </p>
                    <p style={{...styles.sectionBodyText, marginBottom: 30}}>
                    Want to build customizable React Native components quickly? <b>Reduced React</b> writes most of the code for you. Just define the component tree, add some styles, and we’ll do the rest.
                    </p>
                    <div style={{...styles.buttonContainer, marginBottom: 30}} onClick={onClickTryPlayground}>
                        <p style={styles.buttonText}>
                            {content.ctaButtonText}
                        </p>
                    </div>
                </div>
                <div style={styles.heroRightContainer}>
                    <img style={styles.heroBodyImage} src={content.bodyImageSource} />
                </div>
            </div>
            <div style={styles.features}>
                <p style={styles.sectionTitleText}>
                    {content.featureTitle}
                </p>
            </div>
            <div style={styles.copyright}>
                <p style={styles.copyrightText}>
                    {content.copyrightText}
                </p>
            </div>
        </div>
    )

}

export {Landing}
