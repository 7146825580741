import React, {useState, useEffect, useRef} from 'react'

import {Simulator} from '../../components/Simulator'
import {ComponentTree} from '../../components/ComponentTree'
import {PropsInput} from '../../components/PropsInput'

import * as PU from '../../utils/parser'
import {getStyleConstants, constants as SC} from '../../styles/Colors'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import * as CodeStyles from 'react-syntax-highlighter/dist/esm/styles/prism'

const getStyles = (isDarkMode, visibleEditors) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc,
        cs,
        codestyle,
        bgc, bc,
        cp
    } = getStyleConstants(isDarkMode)

    const lastVisibleEditor = visibleEditors.tree ?
        'tree'
        : visibleEditors.sim ?
        'propsInput'
        // : visibleEditors.sim ?
        // 'sim'
        : visibleEditors.js ?
        'js'
        : visibleEditors.props ?
        'props'
        : visibleEditors.styles ?
        'styles'
        : 'ec'


    return ({
        // misc
        codeStyle: CodeStyles[codestyle],
        syntaxHighlighter: {
            height: '100%',
            margin: 0,
            padding: '20px 0px',
            boxSizing: 'border-box',
            backgroundColor: 'transparent',
            fontSize: 14
        },

        // containers
        rootContainer : isFullscreen => (!isFullscreen ?
            {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                overflow: 'hidden',
            }
            : {
                flex: 1,
                position: 'absolute', display: 'flex',
                top: 0, left: 0, height: '100vh', width: '100vw',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                overflow: 'hidden',
                backgroundColor: bgc
            }
        ),
        editorsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flex: 1,
            overflow: 'scroll',
        },
        mainHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${bc}`,
            overflow: 'scroll',
            cursor: 'pointer', padding: 0
        },
        mainHeaderLeftContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingRight: 8
        },

        // file editors
        editorContainer: (editorID) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flex: 1,
            borderRight: editorID === lastVisibleEditor ? 'none' : `1px solid ${bc}`,
            overflow: 'scroll'
        }),
        editorHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${bc}`,
        },
        editorHeaderText: {
            flex: 1,
            fontSize: fsm,
            fontWeight: fwm,
            color: cp,
            margin: 8,
        },
        editorBodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            overflow: 'scroll',
            flex: 1,
            padding: 10,
        },
        editorBodyText: {
            fontSize: fsm,
            fontWeight: fwm,
            color: cp,
        },
        editorBodyTextArea: {
            fontSize: fsm,
            fontWeight: fwm,
            color: cp,
            width: '100%',
            resize: 'none',
            flex: 1,
            backgroundColor: 'transparent',
            border: 'none',
            padding: 10,
            boxSizing: 'border-box'
        },

        // Attribute Editors
        attributeEditorsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            height: '50%'
        },
        attributeEditorContainer: (hasRightBorder) => ({
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            borderRight: hasRightBorder ?
                `1px solid ${bc}`
                : 'none'
            ,
            overflow: 'hidden'
        }),
        attributeEditorHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: `1px solid ${bc}`,
            borderBottom: `1px solid ${bc}`,
        },
        nodeContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            marginBottom: 10
        },
        nodeHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10
        },
        nodeValueInput: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm,
            padding: 5,
            border: `1px solid ${bc}`,
            marginLeft: 20,
            marginBottom: 10
        },
        nodeNameText: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm,
        },
        fieldContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 20,
            marginBottom: 10
        },
        fieldNameText: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm,
        },
        fieldValueText: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm,
        },
        editorHeaderButtonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'flex-end',
        },
        editorHeaderButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: `0px 10px`,
            minWidth: 25,
            borderLeft: `1px solid ${bc}`,
            cursor: 'pointer',
        },
        editorHeaderButtonText: {
            color: tc,
            fontSize: 16,
            fontWeight: fwm
        },
        mainHeaderButtonContainer: (active) => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: `0px 10px`,
            minWidth: 25.5,
            borderLeft: `1px solid ${bc}`,
            cursor: 'pointer',
            borderBottom: active && !isDarkMode ? `2px solid ${tc}` : 'none',
            borderTop: active && !isDarkMode ? `2px solid transparent` : 'none',
        }),
        mainHeaderButtonText: (active) => ({
            color: active ? tc : cp,
            fontSize: 12,
            fontWeight: active ? fwl : fwm,
        })
    })
}

// Static 

const defaultEditorVisibilities = {
    ec: true,
    styles: false,
    props: false,
    js: true,
    sim: true,
    propsInput: false,
    tree: false
}

// Utils

const p = (description, obj) => console.log(`${description} : ${JSON.stringify(obj, null, 4)}`)

// nodeStyles : {[nodeName]: s}
const joinNodeArgs = (nodeStyles, nodeProps) => {
    const ret = Object.fromEntries(
        Object.keys(nodeStyles).map( nn => [
            nn,
            {
                style: nodeStyles[nn],
                props: nodeProps[nn]
            }
        ])
    )
    return ret
}

// currentNodeArgs : {[nodeName]: {style: s, props: s, info: s}}
// childNodeNames  : [node0name, ..., nodeNname]
const getUpdatedNodeArgs = (currentNodeArgs, childNodeNames) => {
    const ret = {}
    childNodeNames.forEach( nn => {
        ret[nn] = currentNodeArgs[nn] || {
            style: '',
            props: '',
            info: ''
        }
    })

    return ret
}

// nodeArgs : {[nodeName]: {style: s, props: s, info: s}}
// argSection : 'style' | 'props' | 'info'
const extractNodeArg = (nodeArgs, argSection) => {
    const ret = Object.fromEntries(
        Object.keys(nodeArgs).map( nn => [
            nn,
            nodeArgs[nn][argSection]
        ])
    )

    return ret
}

// componentDict : {[componentID]: Component, ...}
const formatComponentDict = componentDict => {
    const ret = Object.fromEntries(
        Object.keys(componentDict).map( k => [
            componentDict[k].name,
            componentDict[k]
        ])
    )
    return ret
}

const getUpdatedComponent = (ec, nodeArgs, currentComponent, components, folders, componentFolderID) => {
    let decoded_entity = null
    let updatedComponent = {...currentComponent}

    // try {
        const {lot, component_name, component_prop_types} = PU.get_lot_from_rr(ec, nodeArgs, components, folders, componentFolderID, 4)
        const {encoded_entity, component_object} = PU.get_entity(component_name, lot, 'c')
        decoded_entity = PU.decode_entity_to_string(encoded_entity)
        updatedComponent = {
            ...updatedComponent,
            ...component_object,
            ec,
            js: decoded_entity,
            extra_node_args: getUpdatedNodeArgs(nodeArgs, component_object.children_names),
            prop_types: component_prop_types
        }
        p('proptypes', {component_prop_types})
    // } catch (e) {
    //     console.log(e)
    // }

    return updatedComponent
}

// Component

const ComponentFileEditor = props => {

    const {
        componentID,
        components,
        folders,
        isDarkMode,
        // updateComponent
    } = props

    // State

    const pressedKeys = useRef({})

    const [currentComponentID, setCurrentComponentID] = useState(componentID)
    const [isFullscreen, setIsFullscreen] = useState(false)

    const [editorVisibilitiesVisible, setEditorVisibilitiesVisible] = useState(true)
    const [visibleEditors, setVisibleEditors] = useState(defaultEditorVisibilities)
    const [zoomScales, setZoomScales] = useState({
        sim: 0.6,
        tree: 1
    })
    const [forceRerenderTree, setForceRerenderTree] = useState(false)
    const rerenderTree = () => setForceRerenderTree( c => !c )

    const [nodeStyles, setNodeStyles] = useState(extractNodeArg(components[currentComponentID].extra_node_args, 'style'))
    const [nodeProps, setNodeProps] = useState(extractNodeArg(components[currentComponentID].extra_node_args, 'props'))
    const [ec, setEC] = useState(components[currentComponentID].ec)
    const [isModified, setIsModified] = useState(false)

    // Function Helpers

    const getCurrentFolderID = () => {
        return Object.values(folders).find( f => f.componentID === currentComponentID ).id
    }

    const updateCurrentComponent = (skipRerender=false) => {
        const currentComponent = components[currentComponentID]
        const nodeArgs = joinNodeArgs(nodeStyles, nodeProps)
        const formattedComponents = formatComponentDict(components)
        const updatedComponent = getUpdatedComponent(ec, nodeArgs, currentComponent, formattedComponents, folders, getCurrentFolderID())
        props.updateComponent(updatedComponent)

        if (!skipRerender) {
            const updatedNodeStyles = extractNodeArg(updatedComponent.extra_node_args, 'style')
            const updatedNodeProps = extractNodeArg(updatedComponent.extra_node_args, 'props')
            setNodeStyles(updatedNodeStyles)
            setNodeProps(updatedNodeProps)
        }
        setIsModified(false)
        console.log(
            JSON.stringify(
                {currentComponentID, components},
                null,
                4
            )
        )
    }

    const setNewCurrentComponent = (compID) => {
        const newComponent = components[compID]
        const nodeStyles = extractNodeArg(newComponent.extra_node_args, 'style')
        const nodeProps = extractNodeArg(newComponent.extra_node_args, 'props')

        setCurrentComponentID(compID)
        setEC(newComponent.ec)
        setNodeStyles(nodeStyles)
        setNodeProps(nodeProps)
        setIsModified(false)
    }

    useEffect(() => {
        const events = ['resize', 'fullscreenchange']
        events.forEach( e => {
            window.addEventListener(e, rerenderTree)
        })
        return () => events.forEach( e => {
            window.removeEventListener(e, rerenderTree)
        })
    }, [])

    // useEffect(() => {
    //     const onKeyPressChange = (e, isPressed) => {
    //         pressedKeys.current[e.key] = isPressed
    //         const pk = pressedKeys.current
    //         if (pk.Meta && pk.s) {
    //             e.preventDefault()
    //             updateCurrentComponent()
    //         }
    //     }
    //     const onKeyDown = e => onKeyPressChange(e, true)
    //     const onKeyUp = e => onKeyPressChange(e, false)
    //     const events = [
    //         {name: 'keydown', handler: onKeyDown},
    //         {name: 'keyup', handler: onKeyUp}
    //     ]
    //     events.forEach( e => {
    //         window.addEventListener(e.name, e.handler)
    //     })
    //     return () => events.forEach( e => {
    //         window.removeEventListener(e.name, e.handler)
    //     })
    // }, [componentID, ec, nodeProps, nodeStyles])

    useEffect(() => {
        updateCurrentComponent(true)
        setNewCurrentComponent(componentID)
    }, [componentID])

    useEffect(() => {
        rerenderTree()
    }, [visibleEditors])

    // Variables

    const styles = getStyles(isDarkMode, visibleEditors)
    const component = components[currentComponentID]

    const mainHeaderText = component.name
    const mainHeaderButtonTitles = editorVisibilitiesVisible ? {
        ec: 'RR',
        styles: 'Styles',
        props: 'Props',
        js: 'JS',
        sim: 'Sim',
        tree: 'Tree'
    } : {}

    const editorTitles = {
        ec: 'index.txt',
        styles: 'Styles',
        props: 'Props',
        js: 'index.js',
        sim: 'Simulator Device',
        propsInput: 'Simulator Props',
        tree: 'Component Tree'
    }

    const editorBodies = {
        ec: ec,
        js: component.js,
    }

    const styleEditorNodes = Object.keys(component.children_styles).map( nn => ({
        name: nn,
        fields: component.children_styles[nn]
    }))

    const propsEditorNodes = Object.keys(component.children_props).map( nn => ({
        name: nn,
        fields: component.children_props[nn].map( p => ({
            name: p.name,
            value: p.ref.value || p.ref.name
        }))
    }))

    const propsTypeText = `Type Props : ${JSON.stringify(PU.prop_types_to_object(component.prop_types), null, 4)}`

    // Helpers

    // Functions

    const onEditingEC = e => {
        setEC(e.target.value)
        setIsModified(true)
    }

    const onEditingNodeStyle = (e, nodeName) => {
        setNodeStyles( curr => ({
            ...curr,
            [nodeName]: e.target.value
        }))
        setIsModified(true)
    }

    const onEditingNodeProps = (e, nodeName) => {
        setNodeProps( curr => ({
            ...curr,
            [nodeName]: e.target.value
        }))
        setIsModified(true)
    }

    const onClickZoomEditor = (editor, zoomType) => {
        const increment = {
            sim: 0.1,
            tree: 0.25
        }[editor]

        const diff = zoomType === '+' ?
            increment
            : -1*increment

        setZoomScales( curr => ({
            ...curr,
            [editor]: Math.max(0, curr[editor] + diff)
        }))
    }

    const onClickMainHeaderButton = buttonID => {
        setVisibleEditors(curr => ({
            ...curr,
            [buttonID]: !curr[buttonID]
        }))
    }

    const onClickMainHeader = () => {
        updateCurrentComponent()
    }

    const onClickFullscreen = () => setIsFullscreen(c => !c)

    const onClickEditorVisibilities = () => setEditorVisibilitiesVisible(c => !c)

    const onKeyDownTextarea = e => {
        if (e.key === 'Tab') {
            e.preventDefault()
            let start = e.target.selectionStart
            let val = e.target.value
            e.target.value = val.substr(0, start) + "    " + val.substr(e.target.selectionEnd)
            e.target.selectionStart = e.target.selectionEnd = start + 4
        }
        if (e.key === 'Backspace') {
            let start = e.target.selectionStart
            let val = e.target.value

            const lastTwo = val.substr(start - 2, start)
            const lastFour = val.substr(start - 4, start)
            p('backspace char', {lastTwo, lastFour, val})
            if (lastFour === '    ') {
                e.preventDefault()
                e.target.value = val.substr(0, start - 4) + val.substr(e.target.selectionEnd)
                e.target.selectionStart = e.target.selectionEnd = start - 4
            } else if (lastTwo === '  ') {
                e.preventDefault()
                e.target.value = val.substr(0, start - 2) + val.substr(e.target.selectionEnd)
                e.target.selectionStart = e.target.selectionEnd = start - 2
            }
        }
    }

    return (
        <div style={styles.rootContainer(isFullscreen)}>
            <div style={styles.mainHeaderContainer} onClick={onClickMainHeader}>
                <div style={styles.mainHeaderLeftContainer}>
                    <p style={{...styles.editorHeaderText, marginRight: 10}}>
                        {mainHeaderText}
                    </p>
                    {isModified ?
                        <i
                            className='bi bi-circle-fill'
                            style={{...styles.editorHeaderButtonText, fontSize: 10}}
                        />
                        : null
                    }
                </div>
                <div style={styles.editorHeaderButtonsContainer}>
                    <div style={styles.mainHeaderButtonContainer()}
                        onClick={onClickEditorVisibilities}
                    >   
                        <i className='bi bi-eye' style={{...styles.mainHeaderButtonText(editorVisibilitiesVisible), fontSize: 18}}/>
                    </div>
                    {Object.keys(mainHeaderButtonTitles).map( editorID => (
                        <div style={styles.mainHeaderButtonContainer(visibleEditors[editorID])}
                            onClick={() => onClickMainHeaderButton(editorID)}
                        >
                            <p style={styles.mainHeaderButtonText(visibleEditors[editorID])} className='no-select'>
                                {mainHeaderButtonTitles[editorID]}
                            </p>
                        </div>
                    ))}
                    <div style={styles.mainHeaderButtonContainer()}
                        onClick={onClickFullscreen}
                    >
                        <i className={`bi bi-fullscreen${isFullscreen ? '-exit' : ''}`} style={{...styles.mainHeaderButtonText(false), fontSize: 18}}/>
                    </div>
                </div>
            </div>
            <div style={styles.editorsContainer}>
                {visibleEditors.ec ?
                    <div style={styles.editorContainer('ec')}>
                        <div style={styles.editorHeaderContainer}>
                            <p style={styles.editorHeaderText}>
                                {editorTitles.ec}
                            </p>
                        </div>
                        <div style={{...styles.editorBodyContainer, padding: 0}}>
                            <textarea
                                style={styles.editorBodyTextArea}
                                value={editorBodies.ec}
                                onChange={onEditingEC}
                                onKeyDown={onKeyDownTextarea}
                                spellCheck={false}
                                autoComplete='off'
                                autoCorrect='off'
                                autoCapitalize='off'
                            />
                        </div>
                    </div>
                    : null
                }
                {visibleEditors.styles ? 
                    <div style={styles.editorContainer('styles')}>
                        <div
                            style={styles.editorHeaderContainer}
                        >
                            <p style={styles.editorHeaderText}>
                                {editorTitles.styles}
                            </p>
                        </div>
                        <div style={styles.editorBodyContainer}>
                            {styleEditorNodes.map( n => (
                                <div style={styles.nodeContainer}>
                                    <div style={styles.nodeHeaderContainer}>
                                        <p style={styles.nodeNameText}>
                                            {n.name}
                                        </p>
                                    </div>
                                    <input
                                        style={styles.nodeValueInput}
                                        value={nodeStyles[n.name] || ''}
                                        onChange={e => onEditingNodeStyle(e, n.name)}
                                        spellCheck={false}
                                        autoComplete='off'
                                        autoCorrect='off'
                                        autoCapitalize='off'
                                    />
                                    {n.fields.map( f => (
                                        <div style={styles.fieldContainer}>
                                            <p style={styles.fieldNameText}>
                                                {f.name}
                                            </p>
                                            <p style={styles.fieldValueText}>
                                                {f.value}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    : null
                }
                { visibleEditors.props ? 
                <div style={styles.editorContainer('props')}>
                    <div
                        style={styles.editorHeaderContainer}
                    >
                        <p style={styles.editorHeaderText}>
                            {editorTitles.props}
                        </p>
                    </div>
                    <div style={styles.editorBodyContainer}>
                        <h4 style={{...styles.editorBodyText, whiteSpace: 'pre', marginBottom: 10}}>
                            {propsTypeText}
                        </h4>
                        {propsEditorNodes.map( n => (
                            <div style={styles.nodeContainer}>
                                <div style={styles.nodeHeaderContainer}>
                                    <p style={styles.nodeNameText}>
                                        {n.name}
                                    </p>
                                </div>
                                <input
                                    style={styles.nodeValueInput}
                                    value={nodeProps[n.name] || ''}
                                    onChange={e => onEditingNodeProps(e, n.name)}
                                    spellCheck={false}
                                    autoComplete='off'
                                    autoCorrect='off'
                                    autoCapitalize='off'
                                />
                                {n.fields.map( f => (
                                    <div style={styles.fieldContainer}>
                                        <p style={styles.fieldNameText}>
                                            {f.name}
                                        </p>
                                        <p style={styles.fieldValueText}>
                                            {f.value}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div> : null}
                {visibleEditors.js ?
                    <div style={styles.editorContainer('js')}>
                        <div style={styles.editorHeaderContainer}>
                            <p
                                style={styles.editorHeaderText}
                            >
                                {editorTitles.js}
                            </p>
                        </div>
                        <SyntaxHighlighter
                            language='javascript'
                            style={styles.codeStyle}
                            showLineNumbers={true}
                            customStyle={styles.syntaxHighlighter}
                        >
                            {editorBodies.js}
                        </SyntaxHighlighter>
                    </div>
                    : null
                }
                {visibleEditors.sim ?
                    <div style={styles.editorContainer('sim')}>
                        <div style={styles.editorHeaderContainer}>
                            <p
                                style={styles.editorHeaderText}
                            >
                                {editorTitles.sim}
                            </p>
                            <div style={styles.editorHeaderButtonsContainer}>
                                {['-', '+'].map( (zoomType, i) => (
                                    <div style={styles.editorHeaderButtonContainer}
                                        onClick={() => onClickZoomEditor('sim', zoomType)}
                                    >
                                        <p style={styles.editorHeaderButtonText} className='no-select'>
                                            {zoomType}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Simulator
                            component={component}
                            components={formatComponentDict(components)}
                            zoomScale={zoomScales.sim}
                            isDarkMode={isDarkMode}
                        />
                    </div>
                    : null
                }
                {/* {visibleEditors.sim ?
                    <div style={styles.editorContainer('propsInput')}>
                        <div style={styles.editorHeaderContainer}>
                            <p style={styles.editorHeaderText}>
                                {editorTitles.propsInput}
                            </p>
                        </div>
                        <PropsInput
                            component={component}
                            components={components}
                            isDarkMode={isDarkMode}
                        />
                    </div> : null
                } */}
                {visibleEditors.tree ?
                    <div style={styles.editorContainer('tree')} >
                        <div style={styles.editorHeaderContainer}>
                            <p
                                style={styles.editorHeaderText}
                            >
                                {editorTitles.tree}
                            </p>
                            <div style={styles.editorHeaderButtonsContainer}>
                                {['-', '+'].map( (zoomType, i) => (
                                    <div style={styles.editorHeaderButtonContainer}
                                        onClick={() => onClickZoomEditor('tree', zoomType)}
                                    >
                                        <p style={styles.editorHeaderButtonText}>
                                            {zoomType}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <ComponentTree
                            component={component}
                            isDarkMode={isDarkMode}
                            zoomScale={zoomScales.tree}
                            forceRerender={forceRerenderTree}
                        />
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export {ComponentFileEditor}