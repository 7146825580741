import React, {useState, useEffect, useRef} from 'react'
import {getStyleConstants, constants as SC} from '../../styles/Colors'

const getStyles = (isDarkMode) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc, tct,
        cs,
        codestyle,
        bgc, bc,
        cp
    } = getStyleConstants(isDarkMode)

    return ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'flex-start'
        },
        menuOptionsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            borderRight: `1px solid ${bc}`
        },
        menuContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            width: 250,
            borderRight: `1px solid ${bc}`
        },
        menuContentContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flex: 1,
            padding: 15,
            marginTop: 20,
            overflow: 'scroll'
        },
        menuHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 15px'
        },
        menuHeaderTitle: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwl,
        },
        menuOptionContainer: (isSelected) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            boxSizing: 'border-box',
            height: 50,
            width: 50,
            borderLeft: `4px solid ${isSelected ? tc : 'transparent'}`,
            borderRight: `4px solid transparent`,
            cursor: 'pointer',
            // backgroundColor: !isDarkMode && isSelected ? tct : 'transparent'
        }),
        menuOptionIcon: isSelected => ({
            fontSize: 20,
            color: isSelected ? tc : cs,
        })
    })
}

const SidebarMenu = props => {
    const {
        isDarkMode,
        options,       // {id, title, icon, children}[]
        selectedOptionID,
        // onClickMenuOption : optionID => void
    } = props

    // Helpers

    const isSelectedOption = optionID => optionID === selectedOptionID

    // Effects

    // Variable Declarations

    const styles = getStyles(isDarkMode)

    const menuIsVisible = selectedOptionID !== null

    const selectedOption = options.find(option => option.id === selectedOptionID)

    // Function Declarations

    const onClickMenuOption = optionID => {
        props.onClickMenuOption(optionID)
    }

    return (
        <div style={styles.root}>
            <div style={styles.menuOptionsContainer}>
                {options.map( option => (
                    <div
                        style={styles.menuOptionContainer(isSelectedOption(option.id))}
                        onClick={() => onClickMenuOption(option.id)}
                    >
                        <i className={option.icon} style={styles.menuOptionIcon(isSelectedOption(option.id))} />
                    </div>
                ))}
            </div>
            { menuIsVisible ?
                <div style={styles.menuContainer}>
                    <div style={styles.menuHeaderContainer}>
                        <p style={styles.menuHeaderTitle}>
                            {selectedOption.title}
                        </p>
                    </div>
                    <div style={styles.menuContentContainer}>
                        {selectedOption.children}
                    </div>
                </div>
                : null
            }
        </div>
    )


}

export {SidebarMenu}