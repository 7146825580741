const fwm = '400'
const fwl = '500'

const fss = 13
const fsm = 14
const fsl = 18

const tc = '#7288d9'
const tct = 'rgb(114, 137, 218, 0.25)'

export const constants = {
    fwm, fwl,
    fss, fsm, fsl,
    tc, tct
}


export const getStyleConstants = (isDarkMode) => isDarkMode ? 
    {
        ...constants,
        codestyle : 'vscDarkPlus',
        bgc:'rgb(30,30,30)',
        bc:'rgb(60, 63, 66)',
        cp:'rgb(190, 193, 196)',
        cs: 'rgb(150, 153, 156)',

        // google
        bgc : '#202124',
        cp : '#bdc1c5',
        bc: '#3c4043',

        cp: 'rgb(170, 173, 176)'
    }
    : {
        ...constants,
        codestyle : 'solarizedLight',
        bgc : 'white',
        bc : 'rgb(200, 202, 205)',
        cp : 'rgb(14, 16, 18)',
        cs: 'rgb(60, 62, 64)',

        // bgc : '#f4f2f0',
        // bc : 'rgb(180, 182, 184)',

        cp: 'rgb(50, 53, 56)'
    }