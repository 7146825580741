//// BC (Base Chars)

// BC : N (Numbers)
export const N_0 = 0
export const N_1 = 1
export const N_2 = 2
export const N_3 = 3
export const N_4 = 4
export const N_5 = 5
export const N_6 = 6
export const N_7 = 7
export const N_8 = 8
export const N_9 = 9

// BC : NS (Number Strings)
export const NS_0 = '0'
export const NS_1 = '1'
export const NS_2 = '2'
export const NS_3 = '3'
export const NS_4 = '4'
export const NS_5 = '5'
export const NS_6 = '6'
export const NS_7 = '7'
export const NS_8 = '8'
export const NS_9 = '9'

// BC : A (Alphabet)
export const A_a = 'a'
export const A_b = 'b'
export const A_c = 'c'
export const A_d = 'd'
export const A_e = 'e'
export const A_f = 'f'
export const A_g = 'g'
export const A_h = 'h'
export const A_i = 'i'
export const A_j = 'j'
export const A_k = 'k'
export const A_l = 'l'
export const A_m = 'm'
export const A_n = 'n'
export const A_o = 'o'
export const A_p = 'p'
export const A_q = 'q'
export const A_r = 'r'
export const A_s = 's'
export const A_t = 't'
export const A_u = 'u'
export const A_v = 'v'
export const A_w = 'w'
export const A_x = 'x'
export const A_y = 'y'
export const A_z = 'z'
export const A_A = 'A'
export const A_B = 'B'
export const A_C = 'C'
export const A_D = 'D'
export const A_E = 'E'
export const A_F = 'F'
export const A_G = 'G'
export const A_H = 'H'
export const A_I = 'I'
export const A_J = 'J'
export const A_K = 'K'
export const A_L = 'L'
export const A_M = 'M'
export const A_N = 'N'
export const A_O = 'O'
export const A_P = 'P'
export const A_Q = 'Q'
export const A_R = 'R'
export const A_S = 'S'
export const A_T = 'T'
export const A_U = 'U'
export const A_V = 'V'
export const A_W = 'W'
export const A_X = 'X'
export const A_Y = 'Y'
export const A_Z = 'Z'
// TODO come back to this

// BC : 
export const BC_LB = '{'    // lb
export const BC_RB = '}'    // rb
export const BC_LBS = '['   // lbs 
export const BC_RBS = ']'   // rbs
export const BC_LP = '('    // lp
export const BC_RP = ')'    // rp
export const BC_LA = '<'    // ra
export const BC_RA = '>'    // la
export const BC_EQ = '='    // eq
export const BC_CL = ':'    // col
export const BC_DT = '.'    // dot
export const BC_SP = ' '    // spc
export const BC_SQ = "'"    // sq
export const BC_DQ = '"'    // dq
export const BC_CM = ','    // cm
export const BC_LS = '/'    // rs
export const BC_DA = '-'    // da
export const BC_US = '_'    // us
export const BC_AS = '*'    // as
export const BC_Q  = '?'    // q
export const BC_TB = '\t'   // tb
export const BC_NL = '\n'   // nl
export const BC_N  = ''     // no
export const BC_AR = BC_EQ + BC_RA
export const BC_DP = BC_LP + BC_RP
export const BC_DB = BC_LB + BC_RB

//// BJ : Base Javascript TODO add other languages?
// K (Keywords)
export const K = [
    A_c + A_o + A_n + A_s + A_t,
    A_r + A_e + A_t + A_u + A_r + A_n,
    A_i + A_m + A_p + A_o + A_r + A_t,
    A_e + A_x + A_p + A_o + A_r + A_t,
    A_f + A_r + A_o + A_m,
    A_u + A_s + A_e,
    A_s + A_e + A_t,
    A_a + A_s + A_y + A_n + A_c,
    A_T + A_o + A_u + A_c + A_h + A_a + A_b + A_l + A_e,
    A_a + A_s,
    A_m + A_a + A_p
]
export const K_C = K[N_0]
export const K_R = K[N_1]
export const K_I = K[N_2]
export const K_E = K[N_3]
export const K_F = K[N_4]
export const K_U = K[N_5]
export const K_S = K[N_6]
export const K_A = K[N_7]
export const K_TO = K[N_8]
export const K_AS = K[N_9]
export const K_MP = K[10]

//// BL ( Base Libraries )
export const BL = [
    A_r + A_e + A_a + A_c + A_t,
    A_r + A_e + A_a + A_c + A_t + BC_DA + A_n + A_a + A_t + A_i + A_v + A_e,
    A_c + A_o + A_m + A_p + A_o + A_n + A_e + A_n + A_t + A_s
]
export const BL_R = BL[N_0]
export const BL_RN = BL[N_1]
export const BL_CO = BL[N_2]

//// LA ( Library Abbreviations )
export const LA = [
    A_R,
    A_R + A_N,
    A_C
]
export const LA_R = LA[N_0]
export const LA_RN = LA[N_1]
export const LA_CO = LA[N_2]

// R : D (Default Export)
export const R_D = A_R + A_e + A_a + A_c + A_t    
// R : H (Hooks)
export const R_H = [
    K_U + A_S + A_t + A_a + A_t + A_e,
    K_U + A_E + A_f + A_f + A_e + A_c + A_t,
    K_U + A_R + A_e + A_f
]
export const H_S = R_H[N_0]
export const H_E = R_H[N_1]
export const H_R = R_H[N_2]

// BL : RN (React Native)
// RN : E (Elements)
// E : (Base)
export const RN_E = [
    A_V + A_i + A_e + A_w,
    A_T + A_e + A_x + A_t,
    A_I + A_m + A_a + A_g + A_e,
    A_T + A_e + A_x + A_t + A_I + A_n + A_p + A_u + A_t,
    K_TO + A_O + A_p + A_a + A_c + A_i + A_t + A_y,
    K_TO + A_H + A_i + A_g + A_h + A_l + A_i + A_g + A_h + A_t,
    K_TO + A_W + A_i + A_t + A_h + A_o + A_u + A_t + A_F + A_e + A_e + A_d + A_b + A_a + A_c + A_k,
    A_S + A_c + A_r + A_o + A_l + A_l + A_V + A_i + A_e + A_w,
    A_F + A_l + A_a + A_t + A_L + A_i + A_s + A_t,
    A_S + A_e + A_c + A_t + A_i + A_o + A_n + A_L + A_i + A_s + A_t,
]
export const E_V = RN_E[N_0]
export const E_T = RN_E[N_1]
export const E_I = RN_E[N_2]
export const E_TI = RN_E[N_3]
export const E_TO = RN_E[N_4]
export const E_TH = RN_E[N_5]
export const E_TWF = RN_E[N_6]
export const E_SV = RN_E[N_7]
export const E_FL = RN_E[N_8]
export const E_SL = RN_E[N_9]

export const RN_ELEMENTS = {
    v: {
        type: E_V,
    },
    t: {
        type: E_T,
    },
    i: {
        type: E_I,
    },
    ti: {
        type: E_TI,
    },
    to: {
        type: E_TO,
    },
    th: {
        type: E_TH,
    },
    twf: {
        type: E_TWF,
    },
    sv: {
        type: E_SV,
    },
    fl: {
        type: E_FL,
    },
    sl: {
        type: E_SL,
    }
}

// R : ET ( React Extra Types)
export const R_ET = [
    A_t + A_e + A_r + A_n + A_a + A_r + A_y,
    A_m + A_a + A_p
]
export const ET_TE = R_ET[0]
export const ET_M = R_ET[1]

export const R_EXTRA_TYPES = {
    te: {
        type: ET_TE
    },
    m: {
        type: ET_M
    }
}

// RN : A (API)
// A : S (Stylesheet)
export const RN_A_S =   A_S + A_t + A_y + A_l + A_e + A_S + A_h + A_e + A_e + A_t

// JT (Join Type)
export const JT_NL  = N_0
export const JT_SP  = N_1
export const JT_CM  = N_2
export const JT_US  = N_3
export const JT_N   = N_4
export const JT_DT  = N_5
export const JT     = [
    BC_NL,
    BC_SP,
    BC_CM,
    BC_US,
    BC_N,
    BC_DT,
]

// WT (Wrap Type)
export const WT_SQ      = N_0
export const WT_B       = N_1
export const WT_BS      = N_2
export const WT_P       = N_3
export const WT_N       = N_4
export const WT_NL      = N_5
export const WT_A       = N_6
export const WT         = [
    [BC_SQ, BC_SQ],
    [BC_LB, BC_RB],
    [BC_LBS, BC_RBS],
    [BC_LP, BC_RP],
    [BC_N, BC_N],
    [BC_NL, BC_NL],
    [BC_LA, BC_RA]
]

// GP (Group Prefixes)
export const GP_A    = BC_US
export const GP_F    = BC_US + NS_0
export const GP_L    = BC_US + NS_1
