import React, {useState, useEffect, useRef} from 'react'
import {getStyleConstants} from '../../styles/Colors'
import {Line} from 'react-lineto'

import * as PU from '../../utils/parser'

const getStyles = (isDarkMode, zoomScale=1) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc,
        cs,
        bgc, bc,
        cp
    } = getStyleConstants(isDarkMode)

    return ({
        rootContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            zIndex: 0
        },
        treeContainer: {
            flex: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            overflow: 'scroll'
        },
        optionsContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            borderTop: `1px solid ${bc}`,
            padding: 20,
            overflow: 'scroll',
            boxSizing: 'border-box'
        },
        // tree
        treeRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%'
        },
        nodeContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            border: `1px solid ${bc}`,
            borderRadius: 5,
            padding: 5,
        },
        nodeBodyText: {
            color: cp,
            fontWeight: fwm,
            fontSize: fsm * zoomScale,
        }
    })
}

const ComponentTree = props => {

    const {
        component,
        isDarkMode,
        zoomScale,
        forceRerender
    } = props

    const nodeRefs = useRef([])
    const [didLayoutNodes, setDidLayoutNodes] = useState(false)
    const [pcrLines, setPCRLines] = useState([])

    const invalidatePCRLines = () => {
        nodeRefs.current = []
        setDidLayoutNodes(false)
        setPCRLines([])
    }

    useEffect(() => {
        invalidatePCRLines()
    }, [component, zoomScale, forceRerender])

    useEffect(() => {
        if (didLayoutNodes) {
            const nodeCoords = nodeRefs.current
            .filter( r => !!r )
            .map( r => {
                const {offsetTop, offsetLeft, clientHeight, clientWidth} = r
                return {
                    topMiddle: {
                        x: offsetLeft + clientWidth/2,
                        y: offsetTop
                    },
                    bottomMiddle: {
                        x: offsetLeft + clientWidth/2,
                        y: offsetTop + clientHeight + 2
                    }
                }
            })

            const lines = component.lot.map( (n, i) => {
                const currCoords = nodeCoords[i]
                const parentCoords = nodeCoords[n.node.parent]

                return currCoords && i ? {
                    x0: currCoords.topMiddle.x,
                    y0: currCoords.topMiddle.y,
                    x1: parentCoords.bottomMiddle.x,
                    y1: parentCoords.bottomMiddle.y,
                } : null
            }).filter( l => !!l )
            setPCRLines(lines)

            console.log(JSON.stringify(lines, null, 4))
        }
    }, [didLayoutNodes])

    // Helper Functions

    const getLotIndexes = treeLevels => {
        let lotIndex = 0
        return treeLevels.map( l => l.map( n => lotIndex++ ) )
    }

    // Variable Declarations

    const styles = getStyles(isDarkMode, zoomScale)
    const styleConstants = getStyleConstants(isDarkMode)

    const treeLevels = component.lot.length ?
        PU.tree_to_2d_lot(component.lot[0])
        : []
    const lotIndexes = getLotIndexes(treeLevels)

    // Function Declarations

    if (!component) return null
    return (
        <div style={styles.rootContainer}>
            <div style={styles.treeContainer}>
                {treeLevels.map( (l, i) => (
                    <div style={styles.treeRowContainer}>
                        {l.map( (n, j) => (
                            <div
                                style={styles.nodeContainer}
                                ref={e => {
                                    e && nodeRefs.current.push(e)
                                    if (lotIndexes[i][j] + 1 === component.lot.length) {
                                        setDidLayoutNodes(true)
                                    }
                                }}
                            >
                                {[lotIndexes[i][j], n.node.type, n.node.name].map( t => (
                                    <p style={styles.nodeBodyText}>
                                        {t}
                                    </p>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {didLayoutNodes && pcrLines.length ?
                pcrLines.map( line => (
                    <Line
                        {...line}
                        borderWidth={1}
                        borderColor={styleConstants.bc}
                    />
                ))
                : null
            }
            {/* <div style={styles.optionsContainer}>
                
            </div> */}
        </div>
    )
}

export {ComponentTree}