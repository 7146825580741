import React, {useState, useEffect, useRef} from 'react'
import moment from 'moment'

import {getStyleConstants} from '../../styles/Colors'

// Utils

const p = (description, obj) => console.log(`${description} : ${JSON.stringify(obj, null, 4)}`)

// Styles

const getStyles = (isDarkMode) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc, tct,
        cs,
        bgc, bc,
        cp
    } = getStyleConstants(isDarkMode)

    const rowHeight = 35

    return ({
        rootContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start'
        },
        // containers
        folderContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start'
        },
        folderRowContainer: isSelected => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            height: rowHeight,
            // backgroundColor: isSelected ? tct : 'transparent',
            borderRadius: 5
        }),
        folderChildrenContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            paddingLeft: 10,
            // borderLeft: `1px solid ${bc}`
        },
        folderRowTitleContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flex: 1,
            cursor: 'pointer'
        },
        folderRowButtonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'flex-end',
            cursor: 'pointer'
        },
        buttonContainer: {
            padding: '5px 8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around'
        },

        // elements
        folderTitleInput: {
            color: cp,
            fontSize: fss,
            fontWeight: fwl,
            padding: 5,
            border: `1px solid ${bc}`,
            // height: rowHeight,
            boxSizing: 'border-box',
            width: '100%'
        },
        folderTitle: isSelected => ({
            color: isSelected ? tc : cp,
            fontSize: fss,
            fontWeight: fwl
        }),
        fileTitle: {
            color: cp,
            fontSize: fss,
            fontWeight: fwl
        },
        arrowIcon: {
            color: cp,
            fontSize: 15,
            fontWeight: fwm,
            marginRight: 5
        },
        folderTypeIcon: {
            color: cp,
            fontSize: 20,
            fontWeight: fwm
        },
        buttonIcon: {
            color: tc,
            fontSize: 17,
            fontWeight: fwm
        },
    })
}

const Explorer = props => {

    const {
        isDarkMode,
        currentComponentID,
        components,

        rootFolderID,   // Folder
        folders,        // {[folderID]: Folder}

        // onClickFolderRow,                // folderID => void
        // onSubmitCreateContainerFolder,   // (parentFolderID, folderName) => void
        // onClickCreateComponentFolder,    // (parentFolderID) => void

    } = props

    // State Declarations

    const [expandedFolders, setExpandedFolders] = useState({})

    const [newFolderParentID, setNewFolderParentID] = useState(null)
    const [newFolderName, setNewFolderName] = useState(null)

    // Function Helpers

    const toggleFolderVisbiliity = (folderID) => {
        setExpandedFolders(curr => ({
            ...curr,
            [folderID]: !curr[folderID]
        }))
    }

    const setFolderVisibility = (folderID, isVisibile) => {
        setExpandedFolders(curr => ({
            ...curr,
            [folderID]: isVisibile
        }))
    }

    // Effects

    useEffect(() => {
        const updatedExpandedFolders = Object.fromEntries(
            Object.keys(folders).map( folderID => [
                folderID,
                expandedFolders[folderID] || true
            ])
        )
        setExpandedFolders(updatedExpandedFolders)
    }, [folders])

    // Variable Declarations

    const styles = getStyles(isDarkMode)

    // Function Declarations

    const onClickFolderRow = folderID => {
        // if is component that is not current then set is open
        const f = folders[folderID]
        toggleFolderVisbiliity(folderID)
        props.onClickFolderRow(folderID)
    }

    const onClickCreateContainerFolder = folderID => {
        setFolderVisibility(folderID, true)
        setNewFolderParentID(folderID)
        setNewFolderName('')
    }

    const onClickCreateComponentFolder = folderID => {
        props.onClickCreateComponentFolder(folderID)
        setFolderVisibility(folderID, true)
    }

    const onSubmitCreateContainerFolder = (e) => {
        e.preventDefault()
        if (!newFolderName.length) {
            // display error
        } else {
            props.onSubmitCreateContainerFolder(newFolderParentID, newFolderName)
            setNewFolderName(null)
            setNewFolderParentID(null)
        }
    }

    // Render Functions

    const renderFolder = folder => {
        const buttons = folder.type === 'container' ? [
            {
                iconName: 'bi bi-folder-plus',
                onClick: () => onClickCreateContainerFolder(folder.id)
            },
            {
                iconName: 'bi bi-file-plus',
                onClick: () => onClickCreateComponentFolder(folder.id)
            }
        ] : []

        // p('render folder', {folder, components})

        const arrowIconName = expandedFolders[folder.id] ? 'bi bi-chevron-down' : 'bi bi-chevron-right'
        const isSelected = folder.type === 'component'
            && folder.componentID === currentComponentID
        const folderName = folder.type === 'component' ? 
            components[folder.componentID].name
            : folder.name
        const fileChildren = folder.type === 'component' ?
            [
                {name: 'index.txt', iconName: 'bi bi-file-code'},
                {name: 'index.js', iconName: 'bi bi-file'}
            ] : []

        return (
            <div style={styles.folderContainer}>
                <div style={styles.folderRowContainer(isSelected)}>
                    <div
                        style={styles.folderRowTitleContainer}
                        onClick={() => onClickFolderRow(folder.id)}
                    >
                        <i className={arrowIconName} style={styles.arrowIcon} />
                        <p style={styles.folderTitle(isSelected)} className='no-select'>
                            {folderName}
                        </p>
                    </div>
                    <div style={styles.folderRowButtonsContainer}>
                        {buttons.map( b => (
                            <div
                                style={styles.buttonContainer}
                                onClick={b.onClick}
                            >
                                <i className={b.iconName} style={styles.buttonIcon} />
                            </div>
                        ))}
                    </div>
                </div>
                <div style={styles.folderChildrenContainer}>
                    {folder.id === newFolderParentID ?
                        <form onSubmit={onSubmitCreateContainerFolder}>
                            <input
                                style={styles.folderTitleInput}
                                value={newFolderName}
                                onChange={e => setNewFolderName(e.target.value)}
                            />
                        </form>
                        : null
                    }
                    {expandedFolders[folder.id] ?
                        folder.children.map( f => renderFolder(f) )
                        : null
                    }
                    {expandedFolders[folder.id] ?
                        fileChildren.map( f => (
                            <div style={styles.folderRowContainer(false)}>
                                <div style={styles.folderRowTitleContainer}>
                                    <i className={f.iconName} style={styles.arrowIcon} />
                                    <p style={styles.fileTitle}>
                                        {f.name}
                                    </p>
                                </div>
                            </div>
                        ))
                        : null
                    }
                </div>
            </div>
        )
    }

    return (
        <div style={styles.rootContainer}>
            {folders[rootFolderID] ? 
                renderFolder(folders[rootFolderID])
                : null
            }
        </div>
    )

}

export {Explorer}
