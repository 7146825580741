import React, {useState, useEffect, useRef} from 'react'
import moment from 'moment'
import JSZip from 'jszip'
import {saveAs} from 'file-saver'

import {ComponentFileEditor} from '../../components/ComponentFileEditor'
import {SidebarMenu} from '../../components/SidebarMenu'
import {Explorer} from '../../components/Explorer'

import * as PU from '../../utils/parser'
import * as UC from '../../utils/common'
import {getStyleConstants} from '../../styles/Colors'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import * as CodeStyles from 'react-syntax-highlighter/dist/esm/styles/prism'

// Utils

const p = (description, obj) => console.log(`${description} : ${JSON.stringify(obj, null, 4)}`)

const downloadProject = (projectName, components, rootFolder) => {
    const zip = new JSZip()

    const mergedFiles = {
        input: Object.values(components).map( c => c.ec ).join('\n\n// Component Declaration\n\n'),
        output: Object.values(components).map( c => c.js ).join('\n\n// Component Declaration\n\n')
    }
    zip.file('input.txt', mergedFiles.input)
    zip.file('output.js', mergedFiles.output)

    const folderRefs = {
        [rootFolder.id] : zip.folder(rootFolder.name)
    }

    let q = rootFolder.children
    while (q.length) {
        let nq = []
        q.forEach( f => {
            const parentFolder = folderRefs[f.parentFolderID]
            p('building folders', {parentFolder})
            if (f.type === 'container') {
                const folder = parentFolder.folder(f.name)
                folderRefs[f.id] = folder
                f.children.forEach( f => nq.push(f) )
            } else {
                const component = components[f.componentID]
                p('building folders', {
                    components: Object.keys(components),
                    component: {
                        name: component.name,
                        id: component.id,
                        ec: component.ec
                    } || 'not found',
                    f
                })
                const folder = parentFolder.folder(component.name)
                folderRefs[f.id] = folder
                const files = [
                    {name: 'index.txt', body: component.ec},
                    {name: 'index.js', body: component.js}
                ]
                files.forEach( f => folder.file(f.name, f.body) )
            }
        })
        q = nq
    }
    zip.generateAsync({type: "blob"})
    .then(content => {
        saveAs(content, `${projectName}.zip`)
    })
}

const _downloadProject = (projectName, components) => {
    const zip = new JSZip()
    zip.file('App.js', "import React from 'react'")
    
	const componentsFolder = zip.folder('components')
	Object.values(components).forEach( c => {
		const componentFolder = componentsFolder.folder(c.name)
		componentFolder.file('index.js', c.js)
    })
    componentsFolder.file(
        'index.js',
        Object.values(components).map( c => {
            return `export * from "./${c.name}"`
        })
            .join('\n')
    )

    zip.generateAsync({type: "blob"})
    .then(content => {
        saveAs(content, `${projectName}.zip`)
    })
}

// Styles

const getStyles = (isDarkMode) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc,
        cs,
        bgc, bc,
        cp
    } = getStyleConstants(isDarkMode)

    return ({
        // misc
        codeStyle: CodeStyles[cs],
        syntaxHighlighter: {
            height: '100%',
            margin: 0,
            padding: '20px 0px',
            boxSizing: 'border-box',
            backgroundColor: 'transparent',
            fontSize: 14
        },

        // containers
        rootContainer : {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            height: '100vh',
            width: '100vw',
            boxSizing: 'border-box',
            backgroundColor: bgc,
            overflow: 'hidden',
        },
        contentContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            flex: 1,
            overflow: 'hidden'
        },
        editorsContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            overflow: 'hidden',
        },

        // main header
        mainHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${bc}`,
            padding: '15px 20px'
        },
        mainHeaderTitleText: {
            fontSize: fsl,
            fontWeight: fwl,
            color: cp
        },
        buttonContainer: {
            backgroundColor: 'transparent',
            borderColor: bc
        },
        // folders
        sidebarContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            borderRight: `1px solid ${bc}`
        },
        sidebarItemContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 10,
            cursor: 'pointer'
        },
        sidebarItemIcon: {
            color: cs,
            fontSize: 24,
            fontWeight: fwm,
            cursor: 'pointer'
        },
        foldersContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '10px 20px',
            borderRight: `1px solid ${bc}`,
            maxWidth: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        foldersHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom: 40,
            cursor: 'pointer'
        },
        foldersTitleText: {
            fontSize: fsm,
            fontWeight: fwm,
            color: cp,
        },
        folderContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        folderTitleText: {
            fontSize: fsm,
            color: cp,
            fontWeight: fwm,
            marginBottom: 10,
        },
        fileTitleText: {
            fontSize: fsm,
            fontWeight: fwm,
            color: cp,
            marginBottom: 10,
            marginLeft: 20,
        },
        addComponentContainer: {
            marginTop: 10,
            padding: '10px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'nowrap',
            cursor: 'pointer',
        },
        addComponentText: {
            fontSize: fsm,
            fontWeight: fwl,
            color: tc,
        },
    })
}

// Mark : Types

// E compoentID <=> type === component
const Folder = (name, type, parentFolderID=null, componentID=null, folderID=null) => ({
    id: folderID || UC.uuid(),
    parentFolderID,
    componentID,
    name,
    type,
    children: []
})

const Component = (name, ec, js='') => ({
    id: UC.uuid(),
    name,
    type: name,
    ec: ec || name,
    js,
    props: [],
    children_names: [],
    children_styles: {},
    children_props: {},
    lot: [],
    extra_node_args: {},
    prop_types: []
})

// Mark Constants

const DEFAULT_COMPONENT_NAME = 'ComponentName'
const ROOT_FOLDER_ID = 'root-folder'

const Home = props => {

    // State Declarations

    const [isDarkMode, setIsDarkMode] = useState(false)
    const [selectedMenuOption, setSelectedMenuOption] = useState('explorer')

    const [components, setComponents] = useState({})    // {[compID] : comp}
    const [currentComponentID, setCurrentComponentID] = useState(null)
    
    const foldersRef = useRef({})
    const [folders, setFolders] = useState({})

    // Helper Functions

    const addComponent = (component) => {
        setComponents(curr => ({
            ...curr,
            [component.id]: component
        }))
    }

    const updateComponent = (updatedComponent) => {
        setComponents( curr => ({
            ...curr,
            [updatedComponent.id]: updatedComponent
        }))
    }

    const addFolder = (folder) => {
        foldersRef.current[folder.id] = folder
        if (foldersRef.current[folder.parentFolderID])
            foldersRef.current[folder.parentFolderID].children.push(folder)
        setFolders({...foldersRef.current})
    }

    const createContainerFolder = (parentFolderID, folderName, folderID=null) => {
        const f = Folder(folderName, 'container', parentFolderID, null, folderID)
        addFolder(f)
        return f
    }

    const createComponentFolder = (parentFolderID, componentName=DEFAULT_COMPONENT_NAME) => {
        const c = Component(componentName)
        addComponent(c)
        const f = Folder(c.name, 'component', parentFolderID, c.id)
        addFolder(f)
        setCurrentComponentID(c.id)
        return {f, c}
    }

    // Effects

    useEffect(() => {
        const fs = [
            {name: 'src', id: ROOT_FOLDER_ID, pid: null, componentNames: []},
            {name: 'components', id: null, pid: ROOT_FOLDER_ID, componentNames: ['ComponentName']},
            {name: 'containers', id: null, pid: ROOT_FOLDER_ID, componentNames: ['ScreenName']}
        ]
        fs.forEach( f => {
            const folder = createContainerFolder(f.pid, f.name, f.id)
            f.componentNames.forEach( cn => {
                const {c, f} = createComponentFolder(folder.id, cn)
                p('after adding', {f, c: [c.name, c.id] })

            })
        })

    }, [])

    // Variable Declarations

    const styles = getStyles(isDarkMode)

    const mainHeaderTitleText = 'Reduced React'
    const colorModeText = isDarkMode ? 'Light' : 'Dark'

    const downloadProjectText = 'Download project'

    // Function Declarations

    const onClickColorMode = () => {
        setIsDarkMode(c => !c)
    }

    const onClickMenuOption = optionID => {
        setSelectedMenuOption(curr => curr === optionID ? null : optionID)
    }

    const onClickDownloadProject = () => {
        downloadProject('ReactNativeApp', components, folders[ROOT_FOLDER_ID])
        console.log('did run download project')
    }

    const onClickFolderRow = (folderID) => {
        const folder = folders[folderID]
        if (folder.type === 'component') {
            setCurrentComponentID(folder.componentID)
        }
    }

    // Function Dependent Variables


    const menuOptions = [
        {
            id: 'project',
            title: 'Project',
            icon: 'bi bi-list',
            children: [
                <div
                    className='clear-btn-secondary'
                    style={{...styles.buttonContainer, marginTop: 5}}
                    onClick={onClickDownloadProject}
                >
                    {downloadProjectText}
                </div>
            ]
        },
        {
            id: 'explorer',
            title: 'Explorer',
            icon: 'bi bi-files',
            children: [
                <Explorer
                    {...{
                        isDarkMode,
                        currentComponentID,
                        components,
                        folders,
                        onClickFolderRow
                    }}
                    rootFolderID={ROOT_FOLDER_ID}
                    onClickCreateComponentFolder={createComponentFolder}
                    onSubmitCreateContainerFolder={createContainerFolder}
                />
            ]
        },
        {
            id: 'settings',
            title: 'Settings',
            icon: 'bi bi-gear',
            children: []
        }
    ]

    return (
        <div style={styles.rootContainer}>
            <div style={styles.mainHeaderContainer}>
                <p style={styles.mainHeaderTitleText}>
                    {mainHeaderTitleText}
                </p>
                <div
                    className='clear-btn-secondary'
                    style={styles.buttonContainer}
                    onClick={onClickColorMode}
                >
                    {colorModeText}
                </div>
            </div>
            <div style={styles.contentContainer}>
                <SidebarMenu
                    isDarkMode={isDarkMode}
                    currentComponentID={currentComponentID}
                    selectedOptionID={selectedMenuOption}
                    options={menuOptions}
                    onClickMenuOption={onClickMenuOption}
                />
                {currentComponentID && components[currentComponentID] ?
                    <ComponentFileEditor
                        componentID={currentComponentID}
                        components={components}
                        folders={folders}
                        isDarkMode={isDarkMode}
                        updateComponent={updateComponent}
                    />
                    : null
                }
            </div>
        </div>
    )

}

export {Home}
