import * as b from './base.js'

//// Utils - Helpers

// items : string[][]
export const remove_duplicates = (_0) => {
    const vals = {}
    const indexes = {}

    _0.forEach( (e, i) => {
        const s = JSON.stringify(e)
        if (!vals[s]) {
            vals[s] = true
            indexes[i] = true
        } else {
            indexes[i] = false
        }
    })
    
    const ret = _0.filter( (_, i) => indexes[i] )

    return ret
}

//// Utils - Entity Encode

// entity    : any
// boolean
export const is_list = (_0) =>
    Array.isArray(_0)

// path     : string[]
export const get_element_path = (_0) =>
    [
        [b.JT_DT, b.WT_N],
        ..._0
    ]

// export name   : string
// entity
export const export_named_export = (_0) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_E,
        [
            [b.JT_N, b.WT_B],
            _0
        ]
    ]

// export default export    : string
// lib               : string     
// entity      
export const import_default_export_from_lib = (_0, _1) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_I,
        _0,
        b.K_F,
        [
            [b.JT_N, b.WT_SQ],
            _1
        ]
    ]

// named exports     : string[]
// lib               : string
// entity
export const import_named_exports_from_lib = (_0, _1) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_I,
        [
            [b.JT_CM, b.WT_B],
            ..._0
        ],
        b.K_F,
        [
            [b.JT_N, b.WT_SQ],
            _1
        ]
    ]

// lib      : string
// as       : string
// entity
export const import_lib_as = (_0, _1) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_I,
        b.BC_AS,
        b.K_AS,
        _1,
        b.K_F,
        [
            [b.JT_N, b.WT_SQ],
            _0
        ]
    ]

// field name    : string
// children      : entity[]
export const declare_object_field_with_child = (_0, _1) =>
    [
        [b.JT_SP, b.WT_N],
        _0,
        b.BC_CL,
        _1
    ]

// children      : entity[]
export const declare_object_with_children = (_0) =>
    [
        [[b.JT_CM, b.JT_NL], [b.WT_B, b.WT_NL]],
        ..._0
    ]

// 
export const declare_array_with_children = (_0) =>
    [
        [b.JT_CM, b.WT_BS],
        ..._0
    ]

// child            : entity
// wrap_in_brackets : boolean
export const declare_expression_with_child = (_0, _1) =>
    [
        [b.JT_N, _1 ? b.WT_B : b.WT_N],
        _0
    ]

// boolean expression   : entity
// children             : entity[] : [ret_true, ret_false]
// wrap_in_brackets     : boolean        
export const declare_ternary_expression_with_children = (_0, _1, _2) =>
    declare_expression_with_child([
        [b.JT_NL, b.WT_N],
        [
            [b.JT_SP, b.WT_N],
            _0,
            b.BC_Q
        ],
        _1[0],
        [
            [b.JT_SP, b.WT_N],
            b.BC_CL,
            _1[1]
        ]
    ], _2)

// child     : entity
export const declare_return_with_child = (_0) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_R,
        _0
    ]

// name                 : string
// child (const value)  : entity
export const declare_const_with_child = (_0, _1) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_C,
        _0,
        b.BC_EQ,
        _1
    ]

export const destructure_child = (_0) =>
    [
        [b.JT_N, b.WT_N],
        b.BC_DT,
        b.BC_DT,
        b.BC_DT,
        _0
    ]

// function name     : string    | entity
// args              : string[]  | entity2
export const call_function_with_args = (_0, _1) =>
    [
        [b.JT_N, b.WT_N],
        _0,
        [
            [b.JT_CM, b.WT_P],
            ..._1
        ]
    ]

// async        : boolean
// args         : string[]
// children     : entity[]
// wrap child   : boolean
export const declare_arrow_function_with_args_and_children = (_0, _1, _2, _3=b.N_1) =>
    [
        [b.JT_NL, b.WT_N],
        [
            [b.JT_SP, b.WT_N],
            _0 ? b.K_A : b.BC_N,
            [
                [b.JT_CM, b.WT_P],
                ..._1
            ],
            b.BC_AR,
            // todo : fix -> redo structure this is crap
            _3 ? b.BC_LB : b.BC_N
        ],
        ..._2,
        _3 ? b.BC_RB : b.BC_N
    ]

// name      : string
// args      : string[]
// children  : entity[]
export const declare_function_with_args_and_children = (_0, _1, _2) =>
    [
        [b.JT_NL, b.WT_N],
        [
            [b.JT_SP, b.WT_N],
            b.K_C,
            _0,
            b.BC_EQ,
            [
                [b.JT_CM, b.WT_P],
                ..._1
            ],
            b.BC_AR,
            b.BC_LB
        ],
        ..._2,
        b.BC_RB
    ]

// var name  : string
// init val  : string
export const declare_state_var = (_0, _1) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_C,
        [
            [b.JT_CM, b.WT_BS],
            _0,
            b.GP_F + _0
        ],
        b.BC_EQ,
        [
            [b.JT_N, b.WT_N],
            b.H_S,
            [
                [b.JT_N, b.WT_P],
                _1
            ]
        ]
    ]

// TODO
// var name  : string
// init val  : string
export const declare_ref_var = (_0, _1) =>
    [
        [b.JT_SP, b.WT_N],
        b.K_C,
        _0,
        b.BC_EQ,
        [
            [b.JT_N, b.WT_N],
            b.H_R,
            [
                [b.JT_N, b.WT_P], 
                _1
            ]
        ]
    ]

// children  : entity[]
// deps      : string[]
export const declare_effect_with_children_and_deps = (_0, _1) =>
    call_function_with_args(
        b.H_E,
        [
            declare_arrow_function_with_args_and_children(
                b.N_0,
                [],
                _0
            ),
            declare_array_with_children(
                _1
            )
        ]
    )

// arg name             : string
// child (arg value)    : entity
// entity
export const declare_element_arg_with_child = (_0, _1) =>
        [
            [b.JT_N, b.WT_N],
            _0,
            b.BC_EQ,
            [
                [b.JT_N, b.WT_B],
                _1
            ]
        ]

// element path : entity
// args         : entity[]
// children     : entity[]
export const declare_element_with_args_and_children = (_0, _1, _2) =>
    [
        [b.JT_NL, b.WT_N],
        [
            [b.JT_NL, b.WT_A],
            _0,
            ..._1
        ],
        ..._2,
        [
            [b.JT_N, b.WT_A],
            b.BC_LS,
            _0
        ]
    ]

// variable path    : entity
// map args         : entity[]
// child            : entity
// wrap_in_brackets : boolean
export const declare_map_of_var_with_child = (_0, _1, _2) =>
    declare_expression_with_child([
        [b.JT_N, b.WT_N],
        _0,
        b.BC_DT,
        b.K_MP,
        [
            [b.JT_N, b.WT_P],
            declare_arrow_function_with_args_and_children(
                // todo : fix
                b.N_0,
                _1,
                [_2],
                b.N_0
            )
        ]
    ], _2)

// root                         : node
// parent_node_is_javascript    : boolean
export const declare_elements_from_root = (_0, _1) => {
    const node = _0.node
    console.log(_0)

    const d_e_f_r = __0 => declare_elements_from_root(__0, node.info.is_js)
    const wrap_js_child_in_brackets = !_1

    return node.type === b.ET_TE ?
        declare_ternary_expression_with_children(
            node.custom_props.find( p => p.name === 'booleanValue').value,
            [
                _0.children[0] ? d_e_f_r(_0.children[0]) : 'null',
                _0.children[1] ? d_e_f_r(_0.children[1]) : 'null'
            ],
            wrap_js_child_in_brackets
        )
    : node.type === b.ET_M ?
        declare_map_of_var_with_child(
            node.custom_props.find( p => p.name === 'arrayValue').value,
            ['item', 'index'].map( e => `${node.name}_${e}`),
            _0.children.length === 1 ?
                d_e_f_r(_0.children[0])
                : b.BC_N
            ,
            wrap_js_child_in_brackets
        )
    : declare_element_with_args_and_children(
        get_element_path([node.info.path, node.type].filter( e => !!e )),
        node.props.map( ({name, value}) => declare_element_arg_with_child(name, value)),
        // Todo Fix
        node.type === b.E_T ?
            [
                declare_expression_with_child(
                    node.custom_props.find( p => p.name === 'text').value,
                    1
                ),
                ..._0.children.map( c => d_e_f_r(c) )
            ]
            : _0.children.map( c => d_e_f_r(c) )
    )
}

export const get_entity = (
    entity_name,
    lot,
    // options
    include_comments=true
) => {

    const imports = {}
    lot.forEach( ({node}) => node.info.imports.forEach( ({lib, name}) => {
        if (imports[lib])
            imports[lib][name] = null
        else
            imports[lib] = {[name]: null}
    }))
    let import_statements = [
        import_default_export_from_lib(b.R_D, b.BL_R),
        ...Object.keys(imports).map( lib => {
            const named_exports = Object.keys(imports[lib])
            return import_named_exports_from_lib(named_exports, lib) 
        })
    ]
    
    // let import_statements = [
    //     import_default_export_from_lib(b.R_D, b.BL_R),
    //     import_lib_as(b.BL_R, b.LA_R)
    // ]
    // lot.forEach( n => {
    //     n.node.info.imports.forEach( i => {import_statements.push(i)} )    
    // })
    // import_statements = remove_duplicates(import_statements)

    // import_statements = [
    //     import_default_export_from_lib(b.R_D, b.BL_R),
    // ]

    let variable_declarations = [
        declare_const_with_child(
            'styles',
            call_function_with_args('getStyles',['props.styles'])
        )
    ]
    lot.forEach( n => n.node.vds.forEach( d =>
        variable_declarations.push(declare_const_with_child(
            d.name,
            d.value
        ))
    ))

    let function_declarations = []
    lot.forEach( n => n.node.fds.forEach( f =>
        function_declarations.push(declare_function_with_args_and_children(
            f.name,
            f.args,
            f.children
        ))
    ))

    // temp : todo : move somewhere else
    let prop_declarations = []
    lot.forEach( n => n.node.pds
        .forEach( p =>
        prop_declarations.push({
            name: p.name,
            value: ''
        })
    ))

    const component_object = {
        name: entity_name,
        type: entity_name,
        lot,
        props: prop_declarations,
        info: {
            is_js: false,
        },
        children: [],
        children_names: lot.map( n => n.node.name ),
        children_styles: Object.fromEntries(
            lot.filter( n => !!n.node.style )
            .map( n => [
                n.node.name,
                n.node.style
            ])
        ),
        children_props: Object.fromEntries(
            lot.filter( n => !!n.node.props || !!n.custom_props )
            .map( n => [
                n.node.name,
                [
                    ...n.node.props,
                    ...n.node.custom_props
                ]
            ])
        ),
    }

    const index_entity = [
        [b.JT_NL, b.WT_N],

        ...(include_comments ? ['// Imports \n'] : []),
        ...import_statements,

        ...(include_comments ? ['\n// Styles \n'] : []),
        declare_function_with_args_and_children(
            'getStyles',
            ['customStyles={}'],
            [
                declare_return_with_child(
                    call_function_with_args(
                        get_element_path([
                            b.RN_A_S,
                            'create'
                        ]),
                        [
                            declare_object_with_children(
                                lot
                                .filter( n => ![b.ET_TE, b.ET_M].includes(n.node.type) )
                                .map( n => declare_object_field_with_child(
                                    n.node.name,
                                    declare_object_with_children([
                                        ...n.node.style.map( ({name, value}) => declare_object_field_with_child(
                                            name,
                                            value
                                        )),
                                        destructure_child(get_element_path([
                                            'customStyles',
                                            n.node.name
                                        ]))
                                    ])
                                ))
                            )
                        ]
                    )
                )
            ]
        ),

        ...(include_comments ? ['\n// Component \n'] : []),,
        declare_function_with_args_and_children(
            entity_name,
            ['props'],
            [
                ...(include_comments ? ['\n// Variable Declarations \n'] : []),
                // ...Array(state_var_count).fill(0).map( (_, i) =>
                //     declare_state_var(
                //         `stateVar${i}`,
                //         b.BC_N
                //     )
                // ),
                ...variable_declarations,

                // makes_network_calls ? declare_effect_with_children_and_deps(
                //     ["return 'test'"],
                //     []
                // ) : '',

                ...(include_comments ? ['\n// Function Declarations \n'] : []),
                ...function_declarations,

                ...(include_comments ? ['\n// Render \n'] : []),
                // TODO remove
                lot.length ?
                    declare_return_with_child([
                        [b.JT_NL, [b.WT_P, b.WT_NL]],
                        declare_elements_from_root(
                            lot[0]
                        )
                    ])
                    : b.BC_N
            ]
        ),
        ...(include_comments ? ['\n// Exports \n'] : []),
        export_named_export(
            entity_name
        ),
    ]

    return ({
        encoded_entity: index_entity,
        component_object,
        snippet: component_object.lot.length ? declare_elements_from_root(component_object.lot[0]) : null
    })
}

//// Utils - Entity Decode

export const is_empty = (_0) =>
    is_list(_0) && _0.length == 1

export const is_entity = (_0) =>
    is_list(_0) && _0.length > 1 && is_list(_0[0])

export const get_join_val = (_0) =>
    is_list(_0) ?
        _0.map( i => b.JT[i] ).join('')
        : b.JT[_0]

export const get_left_wrap_val = (_0) =>
    is_list(_0) ?
        _0.map( i => b.WT[i][0] ).join('')
        : b.WT[_0][0]

export const get_right_wrap_val = (_0) =>
    is_list(_0) ?
        _0.reverse().map( i => b.WT[i][1] ).join('')
        : b.WT[_0][1]

// entity to decode : entity
// string
export const decode_entity_to_string = (_0) => {
    if ( is_empty(_0) ) {
        return decode_entity_to_string(
            [_0[0], b.BC_N]
        )
    } else if ( is_entity(_0) ) {
        return `${
            get_left_wrap_val(
                _0[0][1]
            )
        }${
            _0.slice(1)
            .map( e => decode_entity_to_string(e) )
            .join( get_join_val(_0[0][0]) )
        }${
            get_right_wrap_val(
                _0[0][1]
            )
        }`
    } else {
        return _0
    }
}