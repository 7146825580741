import React, {useState, useEffect} from 'react'
import {getStyleConstants} from '../../styles/Colors'

const TERNARY = 'ternary'
const MAP = 'map'
const extra_types = [TERNARY, MAP]

const getStyles = (isDarkMode, deviceWidth=320, deviceHeight=691, deviceScale=0.95) => {
    const {
        fwm, fwl,
        fss, fsm, fsl,
        tc,
        cs,
        bgc, bc,
        cp
    } = getStyleConstants(isDarkMode) 

    return ({
        rootContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            overflow: 'hidden',
        },
        simulatorContainer: {
            flex: 2,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            overflow: 'scroll',
        },
        deviceContainer: {
            width: deviceWidth,
            height: deviceHeight,
            minHeight: deviceHeight,
            maxHeight: deviceHeight,
            minWidth: deviceWidth,
            maxWidth: deviceWidth,
            transform: `scale(${deviceScale})`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            border: `20px solid ${bc}`,
            borderRadius: 20,
            overflow: 'hidden',
            overflow: 'scroll',
        },
        fieldsContainer: {
            flex: 1,
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            borderTop: `1px solid ${bc}`,
            padding: 10,
            overflow: 'scroll',
            boxSizing: 'border-box',
        },
        fieldRowContainer: (isLastRow) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: isLastRow ? 0 : 10
        }),
        fieldTitleText: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm
        },
        fieldValueInput: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm,
            padding: 5,
            border: `1px solid ${bc}`,
            width: '50%'
        },
        fieldValueText: {
            color: cp,
            fontSize: fsm,
            fontWeight: fwm
        },
    })
}

const Simulator = props => {

    const {
        component,
        components,
        zoomScale,
        isDarkMode,
    } = props

    const [componentProps, setComponentProps] = useState({})

    useEffect(() => {
        const updatedComponentProps = Object.fromEntries(
            component.props.map( p => [
                p.name,
                componentProps[p.name] || p.value
            ])
        )
       
        setComponentProps(updatedComponentProps)
    }, [component])

    // Variable Declarations

    const deviceHeight = 650
    const deviceWidth = 320
    const styles = getStyles(isDarkMode, deviceWidth, deviceHeight, zoomScale)

    // Function Helpers

    const getNodeStyle = node => {
        console.log(`simulator styles : ${JSON.stringify(node.style, null, 4)}`)
        let ret = Object.fromEntries(node.style.map( sf => [
            sf.name,
            ["'", '"', '`'].includes(sf.value.charAt(0)) ?
                sf.value.slice(1, -1)
                    : Number(sf.value)
        ]))

        ret = {
            ...ret,
            display: 'flex',
            boxSizing: 'border-box'
        }

        // Conversions ( to css )
        const getBorder = (width, color) => `${width}px solid ${color}`
        if (ret.borderColor) {
            if (ret.borderWidth) {
                ret.border = getBorder(ret.borderWidth, ret.borderColor)
                delete ret.borderWidth
            }
            if (ret.borderTopWidth) {
                ret.borderTop = getBorder(ret.borderTopWidth, ret.borderColor)
                delete ret.borderTopWidth
            }
            if (ret.borderBottomWidth) {
                ret.borderBottom = getBorder(ret.borderBottomWidth, ret.borderColor)
                delete ret.borderBottomWidth
            }
            if (ret.borderLeftWidth) {
                ret.borderLeft = getBorder(ret.borderLeftWidth, ret.borderColor)
                delete ret.borderLeftWidth
            }
            if (ret.borderRightWidth) {
                ret.borderRight = getBorder(ret.borderRightWidth, ret.borderColor)
                delete ret.borderRightWidth
            }
        }
        if (ret.paddingVertical || ret.paddingHorizontal) {
            ret.padding = `${ret.paddingVertical || 0}px ${ret.paddingHorizontal || 0}px`
            delete ret.paddingVertical
            delete ret.paddingHorizontal
        }
        if (ret.marginVertical || ret.marginHorizontal) {
            ret.padding = `${ret.marginVertical || 0}px ${ret.marginHorizontal || 0}px`
            delete ret.paddingVertical
            delete ret.paddingHorizontal
        }

        return ret
    }

    const parseNodePropValue = (nodeType, propName, propValue) => {
        switch (propName) {
            case 'value':
                switch (nodeType) {
                    case 'map':
                        try {
                            return Array.isArray(propValue) ?
                                propValue
                                : JSON.parse(propValue)
                        } catch (e) {
                            console.log(`err parsing \n arr : ${propValue} \n error : ${e}`)

                            return []
                        }
                    case 'ternary':
                        console.log(`ternary ${JSON.stringify({nodeType, propName, propValue})}`)
                        return {
                            'true': true, '1': true,
                            'false': false, '0': false,
                        }[propValue] || false
                }
            case 'source':
                try { return require(`../../assets/${propValue}`) }
                catch (e) { return require('../../assets/pfp3.png') }
            case 'text':
                return propValue || 'Insert Text Here'
            default:
                return propValue
        }
    }

    const getComponentPropValue = (nodeName, nodeType, nodePropName) => {
        const propName = `${nodeName}_${nodePropName}`
        const propValue = componentProps[propName]

        const p = {nodeName, nodeType, nodePropName, propName, propValue}
        console.log(`gcpv ${JSON.stringify(p, null, 4)}`)

        return parseNodePropValue(nodeType, nodePropName, propValue)
    }

    const getFieldFromMapItem = (nodeName, nodeType, nodePropName, mapItem) => {
        const fieldName = `${nodeName}_${nodePropName}`
        const fieldValue = mapItem[fieldName]

        const p = {nodeName, nodeType, fieldName, fieldValue}
        console.log(`getmap : ${JSON.stringify(p, null, 4)}`)

        return parseNodePropValue(nodeType, nodePropName, fieldValue)
    }

    // Function Declarations

    const renderNode = (n, mapItem, nodeNamePrefix='')  => {
        const {node} = n
        console.log(`nodei : ${JSON.stringify({
            name: node.name,
            type: node.type,
            pmn: node.pmn
        }, null, 4)}`)
        const gcpv = (nodePropName) => getComponentPropValue(nodeNamePrefix + node.name, node.type, nodePropName)
        const gfmi = (nodePropName) => getFieldFromMapItem(nodeNamePrefix + node.name, node.type, nodePropName, mapItem)

        if (components[node.type]) {
            console.log()
            return renderNode(components[node.type].lot[0], mapItem, `${node.name}_`)
        }
        switch (node.type) {
            case 'map':
                const mapValue = mapItem ? gfmi('value') : gcpv('value')
                console.log(`did hit map with arr : ${JSON.stringify({name: node.name, mapValue, componentProps}, null, 4)}`)
                return mapValue.map(mapItem => renderNode(node.children[0], mapItem, nodeNamePrefix))
            case 'ternary':
                const ternaryValue = mapItem ? gfmi('value') : gcpv('value')
                return ternaryValue ? renderNode(node.children[0], mapItem, nodeNamePrefix) : null
            case 'View':
            case 'TouchableOpacity':
            case 'TouchableHighlight':
            case 'TouchableWithoutFeedback':
                return <div
                    style={getNodeStyle(node)}
                >
                    {node.children.map( n => renderNode(n, mapItem, nodeNamePrefix) )}
                </div>
            case 'Image':
                const imageSource = mapItem ? gfmi('source') : gcpv('source')
                return <img
                    style={getNodeStyle(node)}
                    src={imageSource}
                />
            case 'Text':
                const textChild = mapItem ? gfmi('text') : gcpv('text')

                return <p
                    style={getNodeStyle(node)}
                >
                    {textChild}
                </p>
            case 'ScrollView':
                return <div
                    style={{
                        ...getNodeStyle(node),
                        overflow: 'scroll'
                    }}
                >
                    {node.children.map( n => renderNode(n, mapItem, nodeNamePrefix) )}
                </div>
            case 'FlatList':
            case 'SectionList':
            default:
                return null
        }
    }

    const onEditingPropField = (e, fieldName) => {
        const text = e.target.value
        setComponentProps( curr => ({
            ...curr,
            [fieldName]: text
        }))
    }

    if (!component) return null
    return (
        <div style={styles.rootContainer}>
            <div style={styles.simulatorContainer}>
                <div style={styles.deviceContainer}>
                    {component.lot.length ?
                        renderNode(component.lot[0])
                        : null
                    }
                </div>
            </div>
            <div style={styles.fieldsContainer}>
                {Object.keys(componentProps).map((cp, i) => (
                    <div style={styles.fieldRowContainer(i + 1 === Object.keys(componentProps).length)}>
                        <p style={styles.fieldTitleText}>
                            {cp}
                        </p>
                        <input
                            style={styles.fieldValueInput}
                            value={componentProps[cp]}
                            onChange={e => onEditingPropField(e, cp)}
                            spellCheck={false}
                            autoComplete='off'
                            autoCorrect='off'
                            autoCapitalize='off'
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export {Simulator}