import * as r from './rules.js'
import * as b from './base.js'

import * as UC from '../common'

// Utils

let p = (d, obj) => console.log(`${d} : ${JSON.stringify(obj, null, 4)}`)
let pri = (d, obj) => console.log(`${d} : ${JSON.stringify(obj, null, 4)}`)

p = pri = () => {}

// set      : {[k0]: any,...,[kn]: any}
// field    : string
// boolean
const set_has = (set, field) => set.hasOwnProperty(field)

// args : string[] : 'a:a0-...-an'[[]]
// arr  : [a0, [a00,...,a0n]][]
const args_to_arr = (args) => args.map( a => [
    a.split(':')[0],
    a.split(':')[1].split('-')
])

// args_arr     : [a0, [a00,...,a0n]][]
// args_dict    : {a0: [a00,...,a0n]}
const args_arr_to_dict = (args_arr) =>
    Object.fromEntries(args_arr.map( ([a, args]) => [
        a,
        args
    ]
))

// args_dict    : {a0: [a00,...,a0n]}
// args_arr     : [a0, [a00,...,a0n]][]
const args_dict_to_arr = (args_dict) =>
    Object.keys(args_dict).map( k => [
        k,
        args_dict[k]
    ])

// Static : Rules / Transforms

const fields = {
    props: {
        // common
        style: {
            name: 'style',
            type: 'v',
            value_type: 'o',
            transform: ({n_name}) => ({
                value: `styles.${n_name}`,  // todo : option to link state ?
            })
        },
        // image
        s: {
            name: 'source',
            type: 'v',
            value_type: 's',
            transform: ({v}) => ({
                value: v,
            })
        },
        rm: {
            name: 'resizeMode',
            type: 'v',
            value_type: 's',
            transform: ({v}) => ({
                value: {
                    cov: 'cover',
                    con: 'contain',
                    str: 'stretch',
                    rep: 'repeat',
                    cen: 'center'
                }[v] || v
            })
        },
        // text
        em: {
            name: 'ellipsizeMode',
            type: 'v',
            value_type: 's',
            transform: ({v}) => ({
                value: {
                    h: 'head',
                    m: 'middle',
                    t: 'tail',
                    c: 'clip'
                }[v] || v,
            })
        },
        nl: {
            name: 'numberOfLines',
            type: 'v',
            value_type: 'n',
            transform: ({v}) => ({
                value: v,
            })
        },
        // touchable
        op: {
            name: 'onPress',
            type: 'f',
            args: [],
            async: false,
            transform: ({}) => ({
                children: []    // todo : add children ?
            })
        },
        // list
        d: {
            name: 'data',
            type: 'v',
            value_type: 'a',
            transform: ({v}) => ({
                value: v
            })
        },
        ri: {
            name: 'renderItem',
            type: 'f',
            args: ['{item, index}'],
            async: false,
            transform: ({v}) => ({
                children: [
                    ...v[0].props.map( ({name}, i) => r.declare_const_with_child(
                        name,
                        `item.${v[i + 1] || name}`
                    )),
                    r.declare_return_with_child(
                        // todo : pass in full component object -> to get props
                        r.declare_element_with_args_and_children(
                            r.get_element_path([v[0].info.path, v[0].name].filter( e => !!e )),
                            v[0].props.map( ({name}) => r.declare_element_arg_with_child(name, name)),
                            [],
                        )
                    )
                ]
            })
        },
        ke: {
            name: 'keyExtractor',
            type: 'f',
            args: ['item', 'index'],
            transform: ({v}) => ({
                children: [
                    r.declare_return_with_child(v ? `item.${v}` : 'index')
                ]
            })
        },
        rsh: {
            name: 'renderSectionHeader',
            type: 'f',
            args: ['{section}'],
            async: false,
            transform: ({}) => ({
                children: [
                    r.declare_return_with_child('null')
                ]
            })
        },
        rsf: {
            name: 'renderSectionFooter',
            type: 'f',
            args: ['{section}'],
            async: false,
            transform: ({}) => ({
                children: [
                    r.declare_return_with_child('null')
                ]
            })
        },
        nc: {
            name: 'numColumns',
            type: 'v',
            value_type: 'n',
            transform: ({v}) => ({
                value: v
            })
        },
        h: {
            name: 'horizontal',
            type: 'v',
            value_type: 'b',
            transform: () => ({
                value: 'true'
            })
        },
        // component props
        cvp: {
            name: 'componentVariableProp',
            type: 'v',
            value_type: 'e',
            transform: ({v}) => ({
                value: v
            })
        },
        cfp: {
            name: 'componentFunctionProp',
            type: 'f',
            args: [],
            async: false,
            transform: ({}) => ({
                children: []
            })
        },
    },
    style: {
        // view
        fd: {
            name: 'flexDirection',
            type: 's',
            transform: ({v}) => ({
                c: 'column',
                r: 'row'
            }[v] || v)
        },
        ai: {
            name: 'alignItems',
            type: 's',
            transform: ({v}) => ({
                c: 'center',
                s: 'stretch',
                fs: 'flex-start',
                fe: 'flex-end'
            }[v] || v)
        },
        jc: {
            name: 'justifyContent',
            type: 's',
            transform: ({v}) => ({
                sa: 'space-around',
                sb: 'space-between',
                fs: 'flex-start',
                fe: 'flex-end'
            }[v] || v)
        },
        f: {
            name: 'flex',
            type: 'n',
            transform: ({v}) => v,
        },
        fwr: {
            name: 'flexWrap',
            type: 's',
            transform: ({v}) => ({
                w: 'wrap',
                n: 'no-wrap',
                wr: 'wrap-reverse'
            }[v] || v)
        },
        bgc: {
            name: 'backgroundColor',
            type: 's',
            transform: ({v}) => v,
        },
        // border
        bw: {
            name: 'borderWidth',
            type: 'n',
            transform: ({v}) => v
        },
        bc: {
            name: 'borderColor',
            type: 's',
            transform: ({v}) => v
        },
        br: {
            name: 'borderRadius',
            type: 'n',
            transform: ({v}) => v
        },
        btw: {
            name: 'borderTopWidth',
            type: 'n',
            transform: ({v}) => v
        },
        bbw: {
            name: 'borderBottomWidth',
            type: 'n',
            transform: ({v}) => v
        },
        brw: {
            name: 'borderRightWidth',
            type: 'n',
            transform: ({v}) => v
        },
        blw: {
            name: 'borderLeftWidth',
            type: 'n',
            transform: ({v}) => v
        },

        // text
        ff: {
            name: 'fontFamily',
            type: 's',
            transform: ({v}) => ({
                ns: 'Nunito Sans'
            }[v] || 'San Francisco')
        },
        fs: {
            name: 'fontSize',
            type: 'n',
            transform: ({v}) => ({
                xs: '10',
                s: '12',
                m: '14',
                l: '18',
                xl: '22'
            }[v] || v)
        },
        fw: {
            name: 'fontWeight',
            type: 's',
            transform: ({v}) => ({
                xs: '200',
                s: '300',
                m: '400',
                l: '500',
                xl: '600'
            }[v] || v)
        },
        c: {
            name: 'color',
            type: 's',
            transform: ({v}) => v
        },
        ls: {
            name: 'letterSpacing',
            type: 'n',
            transform: ({v}) => v
        },
        // margin + padding
        p: {
            name: 'padding',
            type: 'n',
            transform: ({v}) => v
        },
        pv: {
            name: 'paddingVertical',
            type: 'n',
            transform: ({v}) => v
        },
        ph: {
            name: 'paddingHorizontal',
            type: 'n',
            transform: ({v}) => v
        },
        pt: {
            name: 'paddingTop',
            type: 'n',
            transform: ({v}) => v
        },
        pb: {
            name: 'paddingBottom',
            type: 'n',
            transform: ({v}) => v
        },
        pr: {
            name: 'paddingRight',
            type: 'n',
            transform: ({v}) => v
        },
        pl: {
            name: 'paddingLeft',
            type: 'n',
            transform: ({v}) => v
        },
        m: {
            name: 'margin',
            type: 'n',
            transform: ({v}) => v
        },
        mt: {
            name: 'marginTop',
            type: 'n',
            transform: ({v}) => v
        },
        mb: {
            name: 'marginBottom',
            type: 'n',
            transform: ({v}) => v
        },
        mr: {
            name: 'marginRight',
            type: 'n',
            transform: ({v}) => v
        },
        ml: {
            name: 'marginLeft',
            type: 'n',
            transform: ({v}) => v
        },
        mv: {
            name: 'marginVertical',
            type: 'n',
            transform: ({v}) => v
        },
        mh: {
            name: 'marginHorizontal',
            type: 'n',
            transform: ({v}) => v
        },

        // image
        h: {
            name: 'height',
            type: 'n',
            transform: ({v}) => v
        },
        w: {
            name: 'width',
            type: 'n',
            transform: ({v}) => v
        },
    },
    custom_props: {
        // text
        t: {
            name: 'text',
            type: 'v',
            value_type: 's',
            transform: ({v}) => ({
                value: v
            })
        },
        // ternary value
        bv: {
            name: 'booleanValue',
            type: 'v',
            value_type: 'b',
            transform: ({v}) => ({
                value: v
            })
        },
        // map array value
        av: {
            name: 'arrayValue',
            type: 'v',
            value_type: 'a',
            transform: ({v}) => ({
                value: v
            })
        },
    }
}

const field_transforms = {
    props: ({field, v, args, n_name, n_pmn}) => {
        const ret = {
            field,
            source: null,
            component_prop_name: null,
            arg: v,
            args,
            name: null,
            value: null,
            ref: null
        }

        //console.log(`reqfields ${JSON.stringify({field, v, n_name, n_pmn}, null, 4)}`)

        const ret_transform = field.transform({v, n_name})
        const ref_name = `${n_name}_${field.name}`
        const is_map_child = n_pmn.names.length > 0
        const is_default_value = v.startsWith('props.')

        // source
        ret.source = is_map_child ? n_pmn.names[args[1]] : 'props'
        
        // name
        ret.name = field.name

        // component_prop_name
        ret.component_prop_name = is_map_child ? null : ref_name

        // value
        if (!is_map_child)
            ret.value = ref_name
        else if (field.type === 'v')
            ret.value = r.call_function_with_args(
                ref_name,
                n_pmn.args
            )
        else if (field.type === 'f')
            ret.value = r.declare_arrow_function_with_args_and_children(
                field.async,
                field.args,
                [
                    r.call_function_with_args(
                        ref_name,
                        n_pmn.args
                    )
                ],
                false
            )

        // ref
        if (!is_map_child && field.type === 'v')
            ret.ref = {
                name: ref_name,
                value: field.value_type === 's' && !is_default_value ?
                    `"${ret_transform.value}"`
                    : ret_transform.value
            }
        else if (!is_map_child && field.type === 'f')
            ret.ref = {
                name: ref_name,
                args: field.args,
                children: ret_transform.children,
                async: field.async
            }
        else if (field.type === 'v')
            ret.ref = {
                name: ref_name,
                args: n_pmn.args,
                children: [
                    r.declare_return_with_child(
                        ret_transform.value
                    )
                ],
                async: false
            }
        else if (field.type === 'f')
            ret.ref = {
                name: ref_name,
                args: [...field.args, ...n_pmn.args],
                children: ret_transform.children,
                async: field.async
            }

        const p = {
            name: n_name,
            ret
        }
        console.log(JSON.stringify(p, null, 4))

        return ret
    },
    style: ({field, v}) => {
        const ret = {
            arg: v,
            name: field.name,
            value: null
        }

        const ret_transform = field.transform({v})
        const formatted_value = field.type === 's' ?
            `"${ret_transform}"`
            : ret_transform
        ret.value = formatted_value

        return ret
    }
}
field_transforms.custom_props = field_transforms.props

const arg_transforms = {
    props: {},
    style: {
        // common
        s: {
            name: 'spatial',
            fields: [
                ['fd'],
                ['ai'],
                ['jc'],
                ['f'],
                ['fwr']
            ],
            transforms: [
                [v => v],
                [v => v],
                [v => v],
                [v => v],
                [v => v]
            ]
        },

        // border
        bwh: {
            name: 'borderWidthHorizontal',
            fields: [
                ['btw', 'bbw']
            ],
            transforms: [
                [v => v, v => v]
            ]
        },
        bwv: {
            name: 'borderWidthVertical',
            fields: [
                ['blw', 'brw']
            ],
            transforms: [
                [v => v, v => v]
            ]
        },

        // dimensions
        hw: {
            name: 'heightWidth',
            fields: [
                ['h', 'w']
            ],
            transforms: [
                [v => v, v => v]
            ]
        },

        // text
        fp: {
            name: 'fontProperties',
            fields: [
                ['c'],
                //['ff'],
                ['fs'],
                ['fw']
            ],
            transforms: [
                [v => v],
                //[v => v],
                [v => v],
                [v => v]
            ]
        },

    },
    custom_props: {}
}

// arg  : [a0, [a00,...,a0n]] || [a0, [a00,...,a0n]][]
// ret  : []
// type : s : node type
// name : s : node name
// pmn : PMN : parent map nodes
const parse_section_args = (section, arg, ret, n_type, n_name, n_pmn, components) => {
    const p_s_a = a => parse_section_args(section, a, ret, n_type, n_name, n_pmn, components)
    const name = arg[0]
    const args = arg[1]

    if (Array.isArray(name)) {
        return arg.forEach( a => p_s_a(a) )
    } else if (components[n_type] && section==='props') {
        const field_partial = {
            ...fields[section]['cvp'],
            name
        }
        const field_transform = field_transforms[section]
        const transform_arg = {
            field: field_partial,
            v: args[0],
            args,
            n_type,
            n_name,
            n_pmn
        }
        const field_final = field_transform(transform_arg)
        ret.push(field_final)
    } else if (fields[section][name]) {
        const field_partial = fields[section][name]
        const field_transform = field_transforms[section]
        const transform_arg = {
            field: field_partial,
            v: args[0],
            args,
            n_type,
            n_name,
            n_pmn
        }
        const field_final = field_transform(transform_arg)
        ret.push(field_final)
    } else if (arg_transforms[section][name]) {
        return p_s_a(args.map( (a, i) =>
            arg_transforms[section][name].transforms[i].map( (t, j) => [
                arg_transforms[section][name].fields[i][j],
                [t(a)]
            ])
        ))
    }
}

const parse_props_args = (arg, ret, n_type, n_name, n_pmn, components) =>
    parse_section_args('props', arg, ret, n_type, n_name, n_pmn, components)

const parse_style_args = (arg, ret, n_type, n_name, n_pmn, components) =>
    parse_section_args('style', arg, ret, n_type, n_name, n_pmn, components)

const parse_custom_props_args = (arg, ret, n_type, n_name, n_pmn, components) =>
    parse_section_args('custom_props', arg, ret, n_type, n_name, n_pmn, components)

const get_node_props = (pa, type, name, n_pmn, components) => {
    let aa = args_to_arr(pa)
    let ad = args_arr_to_dict(aa)

    // todo : remove : copied from get_n_cp

    const parent_maps_count = n_pmn.names.length
    const is_map_child = parent_maps_count > 0

    const req_fields = [
        [b.E_I, ['s']],
        ...Object.values(components).map( c => [
            c.name,
            c.props.map( p => p.name )
        ])
    ]

    const section = 'props'
    req_fields.forEach( ([t, r_fields]) => {
        if (type !== t) return
        r_fields.forEach( field => {

            const f_name = components[type] ?
                field
                : fields[section][field].name
            const mf_name_default = `${name}_${f_name}`
    
            if (is_map_child) {
                //ad[field] = ad[field] || ['', '']
                ad[field] = ad[field] || ['']
                const parent_map_index_arg = Number(ad[field][0]) || parent_maps_count - 1
                // const map_item_field_arg = ad[field][1] || mf_name_default
                const parent_map_item_arg_index = parent_map_index_arg*2
                
                ad[field] = [`${n_pmn.args[parent_map_item_arg_index]}.${mf_name_default}`, parent_map_index_arg]
            } else {
                ad[field] = ad[field] || [`props.${mf_name_default}`]
            }
        })
    })

    // end

    if (!components[type]) ad.style = ['']
    if ([b.E_TO, b.E_TH, b.E_TWF].includes(type)) {
        ad.op = ad.op || ['']
    }
    if ([b.E_FL, b.E_SL].includes(type)) {
        if (ad.ri && components[ad.ri[0]]) {
            let comp_obj = components[ad.ri[0]]
            comp_obj = {
                ...comp_obj,
                info: {
                    ...comp_obj.info,
                    path: b.BC_N
                }
            }
            ad.ri = [[comp_obj, ...ad.ri.slice(1)]]
        }
        ad.ke = ad.ke || ['']
        ad.d = ad.d || [`props.${name}_data`]
    }
    if (type === b.E_SL) {
        ad.rsh = ['null']
        ad.rsf = ['null']
    }

    aa = args_dict_to_arr(ad)
    console.log(`reqfields ${JSON.stringify({aa}, null, 4)}`)
    let parsed_args = []
    parse_props_args(aa, parsed_args, type, name, n_pmn, components)
    
    return parsed_args
}

const get_node_custom_props = (cpa, type, name, n_pmn, components) => {
    let aa = args_to_arr(cpa)
    let ad = args_arr_to_dict(aa)

    const parent_maps_count = n_pmn.names.length
    const is_map_child = parent_maps_count > 0

    const req_fields = [
        [b.E_T, ['t']],
        [b.ET_M, ['av']],
        [b.ET_TE, ['bv']]
    ]
    const section = 'custom_props'
    req_fields.forEach( ([t, r_fields]) => {
        if (type !== t) return
        r_fields.forEach( field => {

            const f_name = components[type] ?
                field
                : fields[section][field].name
            const mf_name_default = `${name}_${f_name}`
    
            if (is_map_child) {
                //ad[field] = ad[field] || ['', '']
                ad[field] = ad[field] || ['']
                const parent_map_index_arg = Number(ad[field][0]) || parent_maps_count - 1
                // const map_item_field_arg = ad[field][1] || mf_name_default
                const parent_map_item_arg_index = parent_map_index_arg*2
                
                ad[field] = [`${n_pmn.args[parent_map_item_arg_index]}.${mf_name_default}`, parent_map_index_arg]
            } else {
                ad[field] = ad[field] || [`props.${mf_name_default}`]
            }
        })
    })

    aa = args_dict_to_arr(ad)
    let parsed_args = []
    parse_custom_props_args(aa, parsed_args, type, name, n_pmn, components)
    
    return parsed_args
}

const get_node_info = (ia, pa, type, name, n_pmn, components, folders, component_folder_id) => {
    let paa = args_to_arr(pa)
    let pad = args_arr_to_dict(paa)
    const ret = {
        imports: [], // {name: s, lib: s}[]
        is_js: false,
        path: '',   // todo : use this or get rid of it
    }

    const is_r_extra_type = [b.ET_TE, b.ET_M].includes(type)
    const is_component = !!components[type]

    // is_js
    ret.is_js = is_r_extra_type

    // type dependent imports
    if (!is_r_extra_type) {
        const lib = components[type] ?
            get_relative_path(component_folder_id, type, components, folders)
            : b.BL_RN
        ret.imports.push({ name: type, lib })
    }
    if (!is_r_extra_type && !is_component) {
        ret.imports.push({name: b.RN_A_S, lib: b.BL_RN})
    }

    // prop dependent imports
    if (pad.ri) {
        const comp_name = pad.ri[0]
        const lib = get_relative_path(component_folder_id, comp_name, components, folders)
        ret.imports.push({ name: comp_name, lib })
    }

    return ret
}

const get_node_style = (sa, n_pmn, components) => {
    console.log(JSON.stringify(sa, null, 4))
    const args_arr = args_to_arr(sa)

    let parsed_args = []
    parse_style_args(args_arr, parsed_args, null, null, n_pmn, components)
    
    return parsed_args
}

const get_node_variable_declarations = (n_props, n_custom_props, type, name) => {
    const props = [...n_props, ...n_custom_props]
    return props
        .filter( p => !p.ref.children )
        .map( p => p.ref)
}

const get_node_function_declarations = (n_props, n_custom_props, type, name) => {
    const props = [...n_props, ...n_custom_props]
    return props
        .filter( p => !!p.ref.children )
        .map( p => p.ref)
}

const get_node_prop_declarations = (n_props, n_custom_props, type, name) => {
    const props = [...n_props, ...n_custom_props]
    return props
        .filter( p => !p.ref.children )
        .filter( p => p.name !== 'style')
        .map( p => p.ref)
}

const get_node_name = (parent, index, name=null, type) => {
    if (!name) return `element_${parent}_${index}`
    else {
        // temp : no conversion
        return name
        switch (type) {
            case b.E_V:
            case b.E_TO:
            case b.E_TH:
            case b.E_TWF:
                return `${name}Container`
            case b.E_I:
                return `${name}Image`
            case b.E_T:
                return `${name}Text`
            case b.E_FL:
            case b.E_SL:
                return `${name}ListContainer`
            default:
                return name
        }
    }
}

const get_node_type = (t) => {
    return b.RN_ELEMENTS[t] ?
        b.RN_ELEMENTS[t].type
        : b.R_EXTRA_TYPES[t] ?
            b.R_EXTRA_TYPES[t].type
            : t
}

// Utils - Tree

const tree_to_lot = (root) => {
    if (!root) return []
    let lot = []
    let q = [root]
    while (q.length) {
        let nq = []
        q.forEach( n =>
            n.children.forEach( c => nq.push(c) ) 
        )
        lot = [
            ...lot,
            ...q
        ]
        q = [...nq]
    }
    return lot
}

export const tree_to_2d_lot = (root, node_converter=n=>n) => {
    let lot = []
    let q = [root]
    while (q.length) {
        let nq = []
        q.forEach( n =>
            n.children.forEach( c => nq.push(c) ) 
        )
        lot.push(q)
        q = [...nq]
    }
    return lot
}

// Encoded Component

const parse_node_args = (args) => {
    const seperators = {
        p: 'p',
        s: 's',
        i: 'i',
        cp: 'cp'
    }

    const parsed_args = {
        type: args[0],
        name: args[1],
        info: [],
        props: [],
        style: [],
        custom_props: []
    }

    const map = {
        p: parsed_args.props,
        s: parsed_args.style,
        i: parsed_args.info,
        cp: parsed_args.custom_props
    }

    let arg_type = null
    args.slice(2).map( arg => {
        if (seperators[arg]) {
            arg_type = arg
        } else if (map[arg_type]) {
            map[arg_type].push(arg)
        }
    })

    return parsed_args
}

const get_node = (parent, index, node_args, n_pmn, components, folders, component_folder_id) => {
    const parsed_args = parse_node_args(node_args)
    
    const type_arg = parsed_args.type
    const name_arg = parsed_args.name
    const info_args = parsed_args.info
    const prop_args = parsed_args.props
    const style_args = parsed_args.style
    const custom_prop_args = parsed_args.custom_props

    const is_r_extra_type = !!b.R_EXTRA_TYPES[type_arg] && !components[type_arg]
    const has_style = !is_r_extra_type
    const has_props = !is_r_extra_type

    const type = get_node_type(type_arg)
    const name = get_node_name(parent, index, name_arg, type)
    const props = has_props ? get_node_props(prop_args, type, name, n_pmn, components) : []
    const style = has_style ? get_node_style(style_args, n_pmn, components) : []
    const custom_props = get_node_custom_props(custom_prop_args, type, name, n_pmn, components)
    const vds = get_node_variable_declarations(props, custom_props, type, name)
    const fds = get_node_function_declarations(props, custom_props, type, name)
    const pds = get_node_prop_declarations(props, custom_props, type, name)
    const info = get_node_info(info_args, prop_args, type, name, n_pmn, components, folders, component_folder_id)

    const ret = {
        parent,
        index,
        type,
        name,
        style,
        props,
        info,
        custom_props,
        children: [],
        pmn: n_pmn,
        vds,
        fds,
        pds
    }

    console.log(`refined_args ${JSON.stringify(ret, null, 4)}`)

    return ret
}

// Utils : Temp

// pn : Node : parent node
const get_node_pmn = pn => {
    return pn.type === b.R_EXTRA_TYPES.m.type ?
        {
            names: [
                ...pn.pmn.names,
                pn.name
            ],
            args: [
                ...pn.pmn.args,
                `${pn.name}_item`,
                `${pn.name}_index`
            ]
        }
        : pn.pmn
}

// External Usage

const get_line_depth = (line, tab_width) => {
    const line_arr = [...line]
    let space_count = 0
    let i = 0
    while (line_arr[i] === ' ') {
        space_count++
        i++
    }
    return space_count / tab_width
}

const is_blank_line = line => {
    const blank_charset = {}
}

// rr : string : reduced react file
// tabWidth : number : number of spaces in tab
// : componentNodes : {val: {raw: s, args: s[], depth: n}, children: []}
// : componentName : s
const parse_rr = (rr='', tab_width) => {
    const lines = rr
        .split('\n')
        .filter( line => line.length )
    const component_name = lines[0] || ''
    const component_tree_lines = lines.slice(1) || []
    
    const component_nodes = component_tree_lines
        .map( line => line.replace('\t', Array(tab_width).fill(' ').join('')))
        .map( line => ({
            val: {
                raw: line,
                args: line
                    .split(' ')
                    .filter( s => !!s )
                ,
                depth: get_line_depth(line, tab_width)
            },
            children: []
        }) )
    
    return {component_name, component_nodes}
}

const parse_extra_args = (arg_string='') => {
    console.log(arg_string)
    const ret = arg_string
        .split(' ')
        .filter( s => !!s )
    
    return ret
}

const get_node_from_tree_at_lot_index = (root, lot_index) => {
    let q = [root]
    let curr_lot_index = 0
    p('gnftali', {lot_index, curr_lot_index, root_children: root.children})
    while (q.length) {
        let nq = []
        q.forEach( n => {
            p('gnftali', {
                curr_lot_index,
                lot_index,
                n
            })
            if (curr_lot_index == lot_index) {
                console.log('gnftali found parent', {n})
                return n
            }
            curr_lot_index++
            n.children.forEach( c => nq.push(c) )
        })
        q = [...nq]
    }
}

// rr           : string : reduced react file
// extra_node_args : 
// { [n.name : n in C_n] : { style: s, props: s, info : s }
// components   : {[c0.name]:ComponentObject, ..., [cn.name]:ComponentObject}
export const get_lot_from_rr = (rr, extra_node_args, components, folders, component_folder_id, tab_width) => {
    const {component_name, component_nodes} = parse_rr(rr, tab_width)

    let o  = {rr, component_name, component_nodes}
    p('glfrr', o)

    // convert nodes to tree
    let component_tree = null
    const last_seen_at_depth = { }
    component_nodes.forEach( (n, i) => {
        const parent_depth = i ? n.val.depth - 1 : null
        p('inv', {raw: n.val.raw, parent_depth, last_seen: last_seen_at_depth[parent_depth]})
        if (!component_tree)
            component_tree = n
        else
            last_seen_at_depth[parent_depth].children.push(n)

        last_seen_at_depth[n.val.depth] = n
    })

    p('ct', {component_tree})

    // { Node(n) : n in componentTree }
    let q = component_tree ? [{n: component_tree, parent_lot_index: null, parent_node: null}] : []
    let lot_index = 0
    const root_pmn = {names: [], args: []}
    while (q.length) {
        let nq = []
        q.forEach( ({n, parent_lot_index, parent_node}, i) => {
            const node_lot_index = lot_index++
            const node_index = i
            const is_root = !node_lot_index

            const {raw, args, depth} = n.val
            const [type_arg, name_arg, ...rest] = args

            p('nps', {parent_node, parent_lot_index, node_lot_index, name_arg, is_root})

            const node_pmn = is_root ? root_pmn : get_node_pmn(parent_node.node)
            const node_name = get_node_name(parent_lot_index, node_index, name_arg, type_arg)

            const extra_args = extra_node_args[node_name] || {style: '', props: ''}
            p('ea', {extra_args, node_name})
            const full_args = [
                ...args,
                's', ...parse_extra_args(extra_args.style),
                'p', ...parse_extra_args(extra_args.props),
            ]

            const node = get_node(
                parent_lot_index,
                node_index,
                full_args,
                node_pmn,
                components,
                folders,
                component_folder_id
            )

            n.node = node
            n.node.children = n.children

            n.children.forEach( c => {
                nq.push({
                    ['n']: c,
                    parent_lot_index: node_lot_index,
                    parent_node: n
                })
            })
        })
        q = [...nq]
    }

    p('zz', {component_tree})

    const lot = tree_to_lot(component_tree)
    const component_prop_types = get_component_prop_types(component_tree)
    
    return {lot, component_prop_types, component_name}
}

// Mark : Import Path

const get_path_to_root = (folderID, folders) => {
    p('gptr', {folderID, folders: Object.keys(folders)})
    const folder = folders[folderID]
    if (!folder.parentFolderID)
        return [folderID]
    else
        return [folderID, ...get_path_to_root(folder.parentFolderID, folders)]
}

const get_relative_path = (fromFolderID, importeeComponentName, components, folders) => {
    const importee = components[importeeComponentName]
    const toFolderID = Object.values(folders).find( f => f.componentID === importee.id ).id

    const fromFolderToRoot = get_path_to_root(fromFolderID, folders)
    const toFolderToRoot = get_path_to_root(toFolderID, folders)

    const toFolderRoute = Object.fromEntries(
        toFolderToRoot.map( (folderID, i) => [folderID, i] )
    )

    p('grp', {fromFolderToRoot, toFolderToRoot, toFolderRoute})

    let relativePath = ''
    fromFolderToRoot.forEach( (folderID, i) => {
        if (toFolderRoute[folderID]) {
            const backwardsRoute = fromFolderToRoot.slice(0, i)
            const forwardsRoute = toFolderToRoot.slice(0, toFolderRoute[folderID]).reverse()

            const fullRoute = [
                ...backwardsRoute.map( _ => '..' ),
                ...forwardsRoute.map( folderID => {
                    const folder = folders[folderID]
                    if (folder.componentID)
                        return Object.values(components).find( c => c.id === folder.componentID ).name
                    else
                        return folder.name
                })
            ]

            relativePath = fullRoute.join('/')

            p('grp', {backwardsRoute, forwardsRoute, fullRoute, relativePath})
        }
    })

    return relativePath
}

// Mark : Prop Structure



const get_component_prop_types = (component_root) => {
    const ret = []
    let q = component_root ? [component_root] : []

    const sources = {
        props: ret,
    }

    while (q.length) {
        let nq = []
        q.forEach( ({node}) => {
            [...node.props, ...node.custom_props]
            .filter( p => p.field.type === 'v' && p.field.name !== 'style')
            .forEach( p => {
                const { value_type } = p.field
                let prop_field = {
                    id: UC.uuid(),
                    field: p.field,
                    name : p.ref.name,
                    type : {
                        name : value_type,
                    },
                    element_type : null,
                    fields : null
                }
                if (value_type === 'a') {
                    prop_field.element_type = {
                        name : 'o',
                        fields: []
                    }
                } else if (value_type === 'o') {
                    prop_field.fields = []
                }
                sources[p.source].push(prop_field)

                if (node.type === b.ET_M && !sources[node.name])
                    sources[node.name] = prop_field.element_type.fields

                pri('proptypes-iter', {prop_field})
            })

            node.children.forEach( c => nq.push(c) )
        })
        q = [...nq]
        nq = []
    }

    p('proptypes-exit', {ret})
    p('proptypes-conversion', {props_obj: prop_types_to_object(ret)})

    return ret
}

export const prop_types_to_object = (prop_types) => {
    const ret = {}

    let q = [[ret, prop_types]]
    while (q.length) {
        let nq = []
        q.forEach( ([source, items]) => {
            console.log(items)
            items.forEach( p => {
                const field_name = p.name
                let field_value = null
                switch (p.type.name) {
                    case 'o':
                        field_value = {}
                        nq.push([field_value, p.fields])
                        break
                    case 'a':
                        const array_element = {}
                        field_value = [array_element]
                        nq.push([array_element, p.element_type.fields])
                        break
                    case 's':
                        field_value = 'string'
                        break
                    case 'n':
                        field_value = 'number'
                        break
                    case 'b':
                        field_value = 'boolean'
                        break
                    case 'e':
                        field_value = 'expression'
                        break
                    default:
                        field_value = null
                        break
                }
                source[field_name] = field_value
            })
        })
        q = [...nq]
    }

    return ret
}
